<template>
 <body class="flex items-center justify-center">
  <table class="min-w-full table-fixed divide-y divide-gray-300 dark:divide-slate-400">
   <thead class="bg-gray-50 dark:bg-slate-700">
    <tr v-show="this.tablePaginatedData.length > 1">
     <th
      scope="col"
      class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-slate-200 capitalize"
     >
      <button
       class="border-2 rounded-xl"
       @click="showHeaderSelector = !showHeaderSelector"
       @blur="showHeaderSelector = false"
      >
       <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
       >
        <path
         stroke-linecap="round"
         stroke-linejoin="round"
         stroke-width="2"
         d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
        />
       </svg>
      </button>
      <transition
       enter-active-class="transition ease-out duration-100"
       enter-from-class="transform opacity-0 scale-95"
       enter-to-class="transform opacity-100 scale-100"
       leave-active-class="transition ease-in duration-75"
       leave-from-class="transform opacity-100 scale-100"
       leave-to-class="transform opacity-0 scale-95"
      >
       <div
        role="menu"
        tabindex="0"
        v-show="showHeaderSelector || selectorIsActive"
        @focus="selectorIsActive = true"
        @blur="selectorIsActive = false"
        class="mt-0 inline-block rounded absolute w-60 shadow-2xl body__header--menu ring-1 ring-black ring-opacity-5 focus:outline-none"
       >
        <fieldset class="space-y-1 p-2">
         <div
          class="relative flex items-start"
          v-for="(header, idx) in comparisonInfo"
          :key="header.name"
         >
          <div class="flex items-center h-5">
           <input
            @input="removeComparison(header.name, idx)"
            :checked="header.show"
            :id="header.name"
            aria-describedby="comments-description"
            name="comments"
            @focus="selectorIsActive = true"
            @blur="selectorIsActive = false"
            type="checkbox"
            class="focus:ring-green-500 h-4 w-4 body__table-header-filter--text border-gray-300 rounded"
           />
          </div>
          <div class="ml-3 text-sm text-left">
           <label :for="header.name" class="font-medium text-gray-700 capitalize">{{
            $t(header.name)
           }}</label>
          </div>
         </div>
        </fieldset>
        <!-- <div>
                  <button
                    @focus="selectorIsActive = true"
                    @blur="selectorIsActive = false"
                    class="text-right font-medium text-gray-700 -mt-1"
                    @click="clearFilters()"
                  >
                    clear
                  </button>
                </div> -->
       </div>
      </transition>
     </th>
     <th scope="col" class="p-1 uppercase text-md">
      <!-- {{ tablePaginatedData[0]["element"] }} -->
      {{ tablePaginatedData[0]["elementTwo"] }}
     </th>
     <th scope="col" class="p-1 uppercase text-md"></th>
     <th scope="col" class="p-1 uppercase text-md">
      <!-- {{ tablePaginatedData[1]["element"] }} -->
      {{ tablePaginatedData[0]["elementOne"] }}
     </th>
     <th scope="col" class="p-1 uppercase text-md">
      <!-- {{ tablePaginatedData[1]["element"] }} -->
      {{ $t("comparison") }}
     </th>
    </tr>
   </thead>
   <tbody
    v-show="this.tablePaginatedData.length > 1"
    class="flex-1 sm:flex-none text-center divide-y divide-gray-200 bg-white dark:bg-slate-500 dark:divide-slate-400"
   >
    <tr
     v-for="data in this.tablePaginatedData"
     :key="data.id"
     :class="['hover:bg-slate-100 dark:hover:bg-slate-400 h-20']"
    >
     <th class="capitalize">{{ $t(data.name) }}</th>
     <td>
      <div class="text-xl font-semibold encom_primary_text">
       {{
        data.name.toLowerCase().includes("waiting") ||
        data.name.toLowerCase().includes("duration") ||
        data.name.toLowerCase().includes("drop")
         ? getAverageTime(data.statQueueTwo)
         : parseInt(data.statQueueTwo) >= 0
         ? data.statQueueTwo
         : 0
       }}
       <span
        v-show="
         data.name.toLowerCase().includes('coefficient') ||
         data.name.toLowerCase().includes('answered_')
        "
        >%</span
       >
      </div>
     </td>
     <td>
      <span class="ml-2 text-sm font-medium text-gray-500">{{ $t("compareTo") }}</span>
     </td>
     <td>
      <span class="text-xl font-semibold encom_primary_text">
       {{
        data.name.toLowerCase().includes("waiting") ||
        data.name.toLowerCase().includes("duration") ||
        data.name.toLowerCase().includes("drop")
         ? getAverageTime(data.statQueueOne)
         : data.statQueueOne
       }}
       <span
        v-show="
         data.name.toLowerCase().includes('coefficient') ||
         data.name.toLowerCase().includes('answered_')
        "
        >%</span
       >
      </span>
     </td>
     <td>
      <div
       v-show="parseInt(data.change.slice(0, 1)) >= 0"
       :class="[
        data.changeType === 'increase' || data.changeType === 'decreaseOposite'
         ? 'bg-green-100 text-green-800'
         : 'bg-red-100 text-red-800',
        'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0',
       ]"
      >
       <ArrowSmUpIcon
        v-if="data.changeType === 'increase'"
        class="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"
        aria-hidden="true"
       />

       <font-awesome-icon
        v-else-if="data.changeType === 'increaseOposite'"
        icon="tractor"
        class="mr-2 flex-shrink-0 self-center h-4 w-4 text-red-600 rotate-45"
       /><!-- 
              <ArrowSmUpIcon
                v-else-if="data.changeType === 'increaseOposite'"
                class="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500"
                aria-hidden="true"
              /> -->
       <font-awesome-icon
        v-else-if="data.changeType === 'decreaseOposite'"
        icon="rocket"
        class="mr-2 flex-shrink-0 self-center h-4 w-4 text-green-600"
       />
       <!--  <ArrowSmDownIcon
                v-else-if="data.changeType === 'decreaseOposite'"
                class="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"
                aria-hidden="true"
              /> -->
       <ArrowSmDownIcon
        v-else-if="data.changeType === 'decrease'"
        class="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500"
        aria-hidden="true"
       />
       <span class="sr-only">
        {{
         data.changeType === "increase" || data.changeType === "decreaseOposite"
          ? "Increased"
          : "Decreased"
        }}
        by
       </span>
       {{ data.change }}
      </div>
     </td>
    </tr>
   </tbody>
  </table>
 </body>
</template>

<script>
import { mapGetters } from "vuex";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import { ArrowSmDownIcon, ArrowSmUpIcon } from "@heroicons/vue/solid";

export default {
 props: ["account", "tablePaginatedData", "removeComparison", "clearFilters", "comparisonInfo"],
 components: {
  Loading,
  ArrowSmDownIcon,
  ArrowSmUpIcon,
 },
 data() {
  return {
   selectorIsActive: false,
   showHeaderSelector: false,
   isLoading: true,
   fullPage: false,
   activeSorting: "",
  };
 },
 methods: {
  getAverageTime(avg) {
   if (avg > 0) {
    avg = this.timeFormater(avg);
   }
   return avg;
  },
  setSorting(sort, header) {
   this.$emit("sort", sort);
   this.activeSorting = header;
  },
  getYear(date) {
   const dateCreated = new Date(date);
   const formatedDate = dateCreated.getFullYear();

   return String(formatedDate);
  },
  getMonth(date) {
   const dateCreated = new Date(date);
   const formatedDate = ("0" + (dateCreated.getMonth() + 1)).slice(-2);
   return String(formatedDate);
  },
  formatDate(date) {
   const dateCreated = new Date(date);
   const formatedDate =
    ("0" + dateCreated.getDate()).slice(-2) +
    "/" +
    ("0" + (dateCreated.getMonth() + 1)).slice(-2) +
    "/" +
    dateCreated.getFullYear();

   return formatedDate;
  },
  formatDateFrench(date) {
   const dateCreated = new Date(date);
   const formatedDate =
    ("0" + dateCreated.getDate()).slice(-2) +
    "/" +
    ("0" + (dateCreated.getMonth() + 1)).slice(-2) +
    "/" +
    dateCreated.getFullYear();

   return formatedDate;
  },
 },
 mounted() {},
 computed: {
  ...mapGetters(["language"]),
 },
};
</script>

<style>
html,
body {
 height: 100%;
}

@media (min-width: 640px) {
 table {
  display: inline-table !important;
 }

 thead tr:not(:first-child) {
  height: 40px;
  display: none;
 }
}
@media only screen and (max-width: 640px) {
 td {
  height: 40px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
 }

 th {
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  height: 40px;
 }
}
</style>
