<template>
  <body class="flex items-center justify-center">
    <div>
      <table
        :class="[
          'w-full flex flex-row flex-no-wrap sm:bg-white overflow-hidden my-0 table-fixed',
        ]"
      >
        <thead class="bg-gray-50 divide-y divide-gray-200">
          <tr
            class="flex flex-col flex-wrap sm:table-row sm:rounded-none mb-2 sm:mb-0"
          >
            <th
              scope="col"
              class="p-3 text-left capitalize text-sm"
              v-for="header in this.tableHeaders"
              :key="header.name"
            >
              <div
                class="inline-flex lg:mt-1 xl:mt-1 sm:mt-0 cursor-pointer"
                @click="
                  this.setSorting(header.sort, header.name),
                    (header.isSort = !header.isSort)
                "
              >
                <span
                  :class="[
                    header.name === this.activeSorting ? `underline` : '',
                    'mr-1',
                  ]"
                >
                  {{ $t(header.name) }}
                </span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  :class="[
                    header.name === this.activeSorting ? `underline` : '',
                    'ml-2 h-5 w-5',
                  ]"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    :d="[
                      this.activeSorting === header.name && header.isSort
                        ? 'M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z'
                        : this.activeSorting === header.name && !header.isSort
                        ? 'M14.707 10.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 12.586V5a1 1 0 012 0v7.586l2.293-2.293a1 1 0 011.414 0z'
                        : 'M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z',
                    ]"
                  />
                </svg>
              </div>
            </th>
          </tr>
        </thead>
        <tbody
          v-show="this.tablePaginatedData.length > 0"
          class="flex-1 sm:flex-none divide-y divide-gray-200"
        >
          <tr
            v-for="(invoice, invoiceIdx) in this.tablePaginatedData"
            :key="invoice.id"
            :class="[
              invoiceIdx % 2 === 0 ? 'bg-white' : `bg-gray-50`,
              'flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0 hover:bg-gray-100 cursor-pointer',
            ]"
            @click="getPdfFile(invoice.id, this.account)"
          >
            <td class="p-3">
              <span class="cursor-pointer underline hover:text-malachite">{{
                invoice.number
              }}</span>
            </td>
            <td class="p-3 truncate">
              {{ this.formatDate(invoice.date) }}
            </td>
            <td class="p-3 xl:text-right">
              <span v-if="language === 'en'" class="mr-0 sm:mr-60"
                >€ {{ parseFloat(invoice.totalWithoutTax).toFixed(2) }}</span
              >
              <span v-if="language === 'fr'" class="mr-0 sm:mr-60"
                >{{
                  frenchAmount(parseFloat(invoice.totalWithoutTax).toFixed(2))
                }}
                €</span
              >
            </td>
            <td class="p-3 truncate xl:text-right">
              <span v-if="language === 'en'" class="mr-0 sm:mr-60"
                >€ {{ parseFloat(invoice.totalWithTax).toFixed(2) }}</span
              >
              <span v-if="language === 'fr'" class="mr-0 sm:mr-60">
                {{ frenchAmount(parseFloat(invoice.totalWithTax).toFixed(2)) }}
                €</span
              >
            </td>
          </tr>
        </tbody>
      </table>
      <div v-show="this.tablePaginatedData.length < 1" class="text-center py-5">
        <svg
          class="mx-auto h-16 w-16 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            vector-effect="non-scaling-stroke"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
          />
        </svg>

        <h3 class="mt-2 text-xl font-medium text-gray-900">
          {{ $t("warning.noDataFoundTitle") }}
        </h3>
        <p class="mt-1 text-base text-gray-500">
          {{ $t("warning.noResultFoundDescription") }}
        </p>
      </div>
    </div>
  </body>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "InvoiceTable",
  props: [
    "account",
    "searchMonth",
    "searchYear",
    "searchNumb",
    "searchTotalWithTax",
    "searchTotalWithoutTax",
    "tablePaginatedData",
  ],
  components: {
    Loading,
  },
  data() {
    return {
      tableHeaders: [
        { name: "invoiceTable.invoiceNumb", sort: "number", isSort: false },
        { name: "invoiceTable.date", sort: "date", isSort: false },
        {
          name: "invoiceTable.totalWithoutTax",
          sort: "totalWithoutTax",
          isSort: false,
        },
        {
          name: "invoiceTable.totalWithTax",
          sort: "totalWithTax",
          isSort: false,
        },
      ],
      isLoading: true,
      fullPage: false,
      activeSorting: "",
    };
  },
  methods: {
    setSorting(sort, header) {
      this.$emit("sort", sort);
      this.activeSorting = header;
    },
    async getPdfFile(id, account) {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie(
            "API"
          )}/api/v1/invoices/document?customerAccount=${account}&invoiceId=${id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            responseType: "blob",
          }
        );
        const file = new Blob([await res.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      } catch (err) {
        this.errorHandling(err);
        this.$emit("activeBanner");
        this.$emit("success", false);
        this.$emit("successMsg", this.errorDataResponse);
      }
    },
    getYear(date) {
      const dateCreated = new Date(date);
      const formatedDate = dateCreated.getFullYear();

      return String(formatedDate);
    },
    getMonth(date) {
      const dateCreated = new Date(date);
      const formatedDate = ("0" + (dateCreated.getMonth() + 1)).slice(-2);
      return String(formatedDate);
    },
    frenchAmount(amount) {
      let splitedAmount = amount.split(".");
      splitedAmount[0] = splitedAmount[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      let newFormat = splitedAmount[0] + "," + splitedAmount[1];
      return newFormat;
    },
    formatDate(date) {
      const dateCreated = new Date(date);
      const formatedDate =
        ("0" + dateCreated.getDate()).slice(-2) +
        "/" +
        ("0" + (dateCreated.getMonth() + 1)).slice(-2) +
        "/" +
        dateCreated.getFullYear();

      return formatedDate;
    },
    formatDateFrench(date) {
      const dateCreated = new Date(date);
      const formatedDate =
        ("0" + dateCreated.getDate()).slice(-2) +
        "/" +
        ("0" + (dateCreated.getMonth() + 1)).slice(-2) +
        "/" +
        dateCreated.getFullYear();

      return formatedDate;
    },
  },
  mounted() {},
  computed: {
    ...mapGetters(["language"]),
  },
};
</script>

<style>
html,
body {
  height: 100%;
}

@media (min-width: 640px) {
  table {
    display: inline-table !important;
  }

  thead tr:not(:first-child) {
    height: 40px;
    display: none;
  }
}
@media only screen and (max-width: 640px) {
  td {
    height: 40px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  }

  th {
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    height: 40px;
  }
}
</style>
