<template>
 <div class="mt-2 flow-root">
  <div v-if="preview" class="mb-5 rounded">
   <div class="mx-2">
    <div
     v-if="previewData.fails.length > 0"
     :class="[
      showAll ? 'h-auto' : 'h-36',
      'ring-1 ring-red-100 overflow-auto rounded-lg bg-red-50 pt-4 my-2',
     ]"
    >
     <div class="flex px-4">
      <div class="flex-shrink-0">
       <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        class="h-5 w-5 text-red-400"
       >
        <path
         fill-rule="evenodd"
         d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"
         clip-rule="evenodd"
        />
       </svg>
      </div>
      <div class="ml-3">
       <h3 class="text-sm font-semibold text-red-800">
        {{ $t("errorImportUsers", { number: previewData.fails.length }) }}
       </h3>
       <div class="mt-2 text-sm text-red-700">
        <ul role="list" class="list-disc pl-5 space-y-1">
         <li v-for="(error, idx) in previewData.fails" :key="idx">
          {{ error.status_long }}
         </li>
        </ul>
       </div>
      </div>
     </div>
     <div v-show="previewData.fails.length > 3" class="relative sticky bottom-0 text-center">
      <button
       type="button"
       @click="showAll = !showAll"
       class="mb-1 z-10 sticky inline-flex items-center px-2.5 border border-transparent text-xs font-medium rounded text-red-700 bg-red-200 hover:bg-red-300 focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-red-500"
      >
       {{
        !showAll
         ? $t("show") + " " + `${previewData.fails.length}` + " " + $t("errors")
         : $t("showLess")
       }}
      </button>

      <div class="z-0 absolute inset-0 bg-red-100 filter blur-sm"></div>
     </div>
    </div>

    <div v-else class="ring-1 ring-green-100 rounded-lg bg-green-50 p-4 my-2">
     <div class="flex">
      <div class="flex-shrink-0">
       <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        class="h-5 w-5 text-green-400"
       >
        <path
         fill-rule="evenodd"
         d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
         clip-rule="evenodd"
        />
       </svg>
      </div>
      <div class="ml-3">
       <h3 class="text-sm font-semibold text-green-800">
        {{ $t("successfullyUploaded") }}
       </h3>
       <div class="text-sm text-green-700">
        <p>
         {{ $t("clickToValidateDestination", { element: previewData.elements.length }) }}

         <span
          class="capitalize inline-flex justify-center p-1 border border-transparent text-sm font-medium rounded-md text-white body__button"
         >
          {{ $t("validate") }}
         </span>
         {{ $t("below") }}.
        </p>
       </div>
      </div>
     </div>
    </div>
   </div>
   <div class="my-2 flex items-center justify-center gap-x-6">
    <button
     type="button"
     @click="goBack()"
     class="rounded-md bg-white px-3 py-2 border border-gray-300 shadow-sm text-sm font-semibold text-gray-900 hover:bg-gray-50"
    >
     {{ $t("ticket.cancel") }}
    </button>

    <button
     type="button"
     @click="sendDestinations()"
     class="rounded-md body__table--header capitalize px-3 py-2 text-sm font-semibold text-white shadow-sm"
    >
     {{ $t("validate") }}
    </button>
   </div>
  </div>
  <div v-if="preview" class="border-b border-gray-200 pb-2 mb-5">
   <h3 class="text-base font-semibold leading-6 text-gray-900">
    {{ $t("tablePreview") }}
   </h3>
  </div>
  <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
   <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
    <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
     <table class="min-w-full divide-y divide-gray-300">
      <thead class="bg-gray-50">
       <tr>
        <th
         v-for="(header, index) in thead"
         :key="index"
         scope="col"
         :class="{ 'pl-4 pr-3 sm:pl-6': index === 0, 'px-3': index !== 0 }"
         class="capitalize py-3.5 text-left"
        >
         <button
          @click="setSorting(header.sort, header.name)"
          class="text-sm font-semibold text-gray-900 group inline-flex"
         >
          {{ $t(header.name) }}

          <span
           v-if="header.isSort && header.sort"
           class="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300"
          >
           <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            class="w-5 h-5"
           >
            <path
             fill-rule="evenodd"
             d="M14.77 12.79a.75.75 0 01-1.06-.02L10 8.832 6.29 12.77a.75.75 0 11-1.08-1.04l4.25-4.5a.75.75 0 011.08 0l4.25 4.5a.75.75 0 01-.02 1.06z"
             clip-rule="evenodd"
            />
           </svg>
          </span>

          <span
           v-else-if="header.sort"
           class="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300"
          >
           <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            class="w-5 h-5"
           >
            <path
             fill-rule="evenodd"
             d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
             clip-rule="evenodd"
            />
           </svg>
          </span>
         </button>
        </th>
        <th v-if="!preview" scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
         <span class="sr-only">Edit</span>
        </th>
       </tr>
      </thead>

      <tbody class="divide-y divide-gray-200 bg-white">
       <tr
        v-for="data in pagination().querySet"
        :key="data.id"
        :class="{
         'cursor-pointer':
          userPermissions.permissions.includes('smart-routings.*') ||
          userPermissions.permissions.includes('smart-routings.delete'),
        }"
        class="hover:bg-gray-50"
        @click="
         (!preview && userPermissions.permissions.includes('smart-routings.*')) ||
         (!preview && userPermissions.permissions.includes('smart-routings.edit'))
          ? $emit('openEditForm', data.id, data.destination, data.origin)
          : ''
        "
       >
        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
         {{ data.origin }}
        </td>

        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
         {{ data.destination }}
        </td>
        <td v-if="!preview" class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 capitalize">
         {{ $d(data.created_at, "shortText") }}
        </td>
        <td v-if="!preview" class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 capitalize">
         {{ $d(data.updated_at, "longText") }}
        </td>

        <td
         v-if="!preview"
         class="px-6 py-6 whitespace-nowrap text-right text-sm font-medium flex justify-end"
        >
         <a
          v-show="
           userPermissions.permissions.includes('smart-routings.*') ||
           userPermissions.permissions.includes('smart-routings.delete')
          "
          href="javascript:void(0)"
          @click.stop="$emit('deleteDestination', data.id, data.origin)"
          class="p-1 shadow-sm border-2 border-black rounded-md cursor-pointer mr-1 hover:bg-red-50 hover:text-red-500 hover:border-red-500"
          :title="$t('delete')"
         >
          <svg
           xmlns="http://www.w3.org/2000/svg"
           class="h-4 w-4"
           viewBox="0 0 20 20"
           fill="currentColor"
          >
           <path
            fill-rule="evenodd"
            d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
            clip-rule="evenodd"
           /></svg
         ></a>
        </td>
       </tr>
      </tbody>
     </table>

     <PaginationComponent
      :backend_pagination="false"
      :current_page="1"
      first_page_url="first_page_url"
      :from="this.rows * this.page - this.rows + 1"
      :last_page="10"
      last_page_url="data.last_page_url"
      links="data.links"
      next_page_url="data.next_page_url"
      :per_page="5"
      :to="getPageLength()"
      :total="this.tbody.length"
      :rows="rows"
      :select_rows="selectRows"
      @setRows="selectedRows = $event"
      @action="$emit('action', $event)"
      @previousPage="previousPage()"
      @nextPage="nextPage()"
     />
    </div>
   </div>
  </div>
 </div>

 <ask-confirmation-dialog ref="askConfirmationDialog"></ask-confirmation-dialog>
</template>

<script>
import axios from "axios";

import PaginationComponent from "./PaginationComponent.vue";

const account = localStorage.getItem("account");
const userPermissions = JSON.parse(localStorage.getItem("userPermissions"));

export default {
 name: "TableComponent",
 props: {
  data: {
   type: Array,
   required: true,
  },
  thead: {
   type: Array,
   required: true,
  },
  tbody: {
   type: Array,
   required: true,
  },
  preview: {
   type: Boolean,
   required: false,
  },
  previewData: {
   type: Array,
   required: false,
  },
  id: {
   type: Number,
   required: false,
  },
 },
 emits: ["actionDetail"],
 components: {
  PaginationComponent,
 },
 data() {
  return {
   account,
   page: 1,
   rows: 10,
   showAll: false,
   selectedRows: null,
   selectRows: [5, 10, 15, 20, 25, 30],
   userPermissions,
  };
 },
 methods: {
  setSorting(sort, header) {
   this.$emit("sort", sort);
   this.activeSorting = header;

   // Set the selected header's isSort value to the opposite of its current value
   const selectedHeader = this.thead.find((header) => header.sort === sort);
   selectedHeader.isSort = !selectedHeader.isSort;
   // Loop through the other headers and set their isSort value to false
   this.thead.forEach((header) => {
    if (header.sort !== sort) {
     header.isSort = false;
    }
   });
  },
  getPageLength() {
   if (this.page === this.pagination().pages) {
    return this.tbody.length;
   } else {
    return this.rows * this.page;
   }
  },
  pagination() {
   if (this.selectedRows) {
    this.rows = this.selectedRows;
   }
   let trimStart = (this.page - 1) * parseInt(this.rows);
   let trimEnd = trimStart + parseInt(this.rows);
   let trimmedData = this.tbody.slice(trimStart, trimEnd);
   let pages = Math.ceil(this.tbody.length / this.rows);
   return {
    querySet: trimmedData,
    pages: pages,
   };
  },
  nextPage() {
   if (this.page < this.pagination().pages) {
    this.page = parseInt(this.page) + 1;
   } else {
    this.page = this.page;
   }
  },
  previousPage() {
   if (this.page > 1) {
    this.page = parseInt(this.page) - 1;
   } else {
    this.page = this.page;
   }
  },
  goBack() {
   location.reload();
  },

  sendDestinations() {
   this.$emit("setLoading", true);

   const options = {
    method: "POST",
    url: `${this.$cookie.getCookie("API")}/api/v1/ctiDestinations/confirmation`,
    params: {
     customerAccount: this.account,
     cti_id: this.id,
     operation_key: this.previewData.operation_key,
    },
    headers: {
     "Content-Type": "application/json",
     Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
   };
   axios
    .request(options)
    .then((res) => {
     let msg = this.$t("elementCreatedSuccessfully", { element: this.$t("destination") });
     this.$emit("activeBanner");
     this.$emit("success", true);
     this.$emit("successMsg", msg);
     setTimeout(() => {
      location.reload();
     }, 1400); // Delay for 2 seconds
    })
    .catch((error) => {
     this.errorHandling(error);
     this.$emit("setLoading", false);
    });
  },
 },
};
</script>

<style></style>
