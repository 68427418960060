<template>
  <div>
    <td
      class="px-6 py-6 whitespace-nowrap text-right text-sm font-medium flex justify-end"
    >
      <button
        :class="
          `p-1 shadow-sm border-2 border-black rounded-md body__table--button cursor-pointer tooltip mr-1`
        "
        @click="$emit('openEditForm')"
        :title="$t('edit')"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-4 w-4"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"
          />
          <path
            fill-rule="evenodd"
            d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
      <a
        href="javascript:void(0)"
        @click.prevent="
          deleteCampaign(
            this.id,
            `Delete '${this.name}'`,
            'Do you wish to continue?',
            'Continue',
            'Go back',
            'Campaign deleted successfully'
          )
        "
        :class="
          `p-1 shadow-sm border-2 border-black rounded-md body__table--button cursor-pointer tooltip mr-1`
        "
        :title="$t('delete')"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-4 w-4"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
            clip-rule="evenodd"
          /></svg
      ></a>
    </td>
  </div>
  <ask-confirmation-dialog
    ref="askConfirmationDialog"
  ></ask-confirmation-dialog>
</template>

<script>
import axios from "axios";

import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";

import AskConfirmationDialog from "../AskConfirmationDialog.vue";
import HoverTooltip from "../HoverTooltip.vue";

const account = localStorage.getItem("account");

export default {
  name: "UpdateCampaigns",
  props: ["id", "name"],
  components: {
    Popover,
    PopoverButton,
    PopoverPanel,
    HoverTooltip,
    AskConfirmationDialog,
  },
  data() {
    return {
      account,
      showMessage: false,
      messageToShow: "",
      subMessageToShow: "",
      confirmationMessage: "",
      openEditForm: false,
    };
  },
  methods: {
    async deleteCampaign(
      id,
      title,
      confirmationMessage,
      confirmButton,
      goBack,
      successMsg
    ) {
      const options = {
        method: "DELETE",
        url: `https://marketing-sms.bluerock.tech/api/campaigns/${id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const r = await this.$refs.askConfirmationDialog.show({
        title: title,
        message: confirmationMessage,
        okButton: confirmButton,
        cancelButton: goBack,
        action: "delete",
      });
      if (r) {
        axios
          .request(options)
          .then((response) => {
            this.$emit("activeBanner");
            this.$emit("success", true);
            this.$emit("successMsg", successMsg);
          })
          .catch((err) => {
            this.errorHandling(err);
            this.$emit("activeBanner");
            this.$emit("success", false);
            this.$emit("successMsg", err.response.data.message);
          });
      }
    },

    sendMessage(msg, subMsg) {
      this.showMessage = true;
      this.messageToShow = msg;
      this.subMessageToShow = subMsg;
    },
  },
};
</script>

<style></style>
