<template>
  <!-- Activity list (smallest breakpoint only) -->
  <div class="sm:hidden">
    <ul role="list" class="divide-y divide-gray-200 overflow-hidden sm:hidden">
      <li
        v-for="ticket in this.tablePaginatedData"
        :key="ticket.id"
        class="px-4 py-4 bg-white hover:bg-gray-50 flex flex-col flex-no wrap sm:table-row sm:mb-0 cursor-pointer"
      >
        <a class="block">
          <span class="flex items-center space-x-4">
            <span class="flex-1 flex space-x-2 truncate">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 5v2m0 4v2m0 4v2M5 5a2 2 0 00-2 2v3a2 2 0 110 4v3a2 2 0 002 2h14a2 2 0 002-2v-3a2 2 0 110-4V7a2 2 0 00-2-2H5z"
                />
              </svg>
              <span class="flex flex-col text-gray-500 text-sm truncate">
                <span class="truncate font-semibold"
                  >{{ ticket.name }}
                  <span
                    :class="[
                      ticket.status_id == 1
                        ? 'encom_ouvert_ticket'
                        : ticket.status_id == 2
                        ? 'encom_en_traitement_ticket'
                        : ticket.status_id == 3
                        ? 'encom_pink_bg'
                        : 'encom_closed_ticket',
                      'text-white px-2 inline-flex text-xs leading-5 font-semibold rounded-full',
                    ]"
                  >
                    {{ checkStatus(ticket.status_id) }}
                  </span>
                </span>

                <time
                  v-if="this.language === 'en'"
                  :datetime="ticket.created_at"
                  >{{ $t("ticketTable.createdAt") }}
                  {{ formatDate(ticket.created_at) }}</time
                >
                <time
                  v-if="this.language === 'fr'"
                  :datetime="ticket.created_at"
                  >{{ $t("ticketTable.createdAt") }}
                  {{ formatDateFrench(ticket.updated_at) }}</time
                >
              </span>
            </span>

            <ChevronRightIcon
              class="flex-shrink-0 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </a>
        <button
          @click="
            this.openOrCloseTicket(
              ticket.open,
              ticket.id,
              $t('ticketTable.confirmationOpenMsg'),
              $t('ticketTable.confirmationCloseMsg'),
              $t('ticketTable.reopenMsg'),
              $t('ticketTable.closeMsg'),
              $t('ticketTable.successMsg'),
              $t('ticketTable.dialogTitleOpen'),
              $t('ticketTable.dialogTitleClose'),
              $t('dialog.confirm'),
              $t('dialog.goBack')
            )
          "
          class="ml-7 mt-1 cursor-pointer rounded px-2 w-24 sm:w-40 text-gray-800 border bg-gray-50"
        >
          <div class="inline-flex py-1.5 text-xs" v-if="ticket.open == 0">
            <span class="flex">
              <LockOpenIcon
                class="flex-shrink-0 h-4 w-4 mr-0.5 encom_pink_text"
                aria-hidden="true"
              />
              {{ $t("ticketTable.reopenTicket") }}
            </span>
          </div>
          <div class="inline-flex py-1.5 text-xs" v-if="ticket.open == 1">
            <LockClosedIcon
              class="flex-shrink-0 h-4 w-4 mr-0.5 encom_closed_ticket_text"
              aria-hidden="true"
            />
            <span>{{ $t("ticketTable.closeTicket") }}</span>
          </div>
        </button>
      </li>
    </ul>
  </div>
  <!-- Table -->

  <body class="flex items-center justify-center">
    <div class="overflow-hidden sm:rounded-t-lg">
      <table
        class="w-full flex flex-row flex-no-wrap sm:bg-white overflow-hidden my-0 table-fixed"
      >
        <thead class="bg-gray-50 divide-y divide-gray-200">
          <tr
            v-if="activeTab === 'recipient'"
            class="flex flex-col flex-wrap sm:table-row sm:rounded-none mb-2 sm:mb-0"
          >
            <th
              class="p-3 text-left capitalize text-sm"
              v-for="header in tableHeaders"
              :key="header.name"
            >
              <div
                v-if="
                  header.name === 'ticketTable.status' ||
                    header.name === 'ticketTable.priority'
                "
                :class="[
                  header.name === 'ticketTable.updatedDate' ? 'py-3' : '',
                  'inline-flex sm:mt-0 cursor-pointer truncate',
                ]"
              ></div>
              <div
                v-else
                class="inline-flex sm:mt-0 cursor-pointer"
                @click="
                  this.setSorting(header.sort, header.name),
                    (header.isSort = !header.isSort)
                "
              >
                <span
                  :class="[
                    header.name === this.activeSorting ? `underline` : '',
                    'mr-1',
                  ]"
                >
                  {{ $t(header.name) }}
                </span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  :class="[
                    header.name === this.activeSorting ? `underline` : '',
                    'ml-2 h-5 w-5',
                    header.name === this.activeSorting ? `underline` : '',
                    'ml-2 h-5 w-5',
                  ]"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    :d="[
                      this.activeSorting === header.name && header.isSort
                        ? 'M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z'
                        : this.activeSorting === header.name && !header.isSort
                        ? 'M14.707 10.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 12.586V5a1 1 0 012 0v7.586l2.293-2.293a1 1 0 011.414 0z'
                        : 'M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z',
                    ]"
                  />
                </svg>
              </div>
            </th>
            <!-- <th scope="col" class="relative px-6 py-3">
              <span class="sr-only">Actions</span>
            </th> -->
          </tr>
          <tr
            v-else
            class="flex flex-col flex-wrap sm:table-row sm:rounded-none mb-2 sm:mb-0"
          >
            <th
              class="p-3 text-left capitalize text-sm"
              v-for="header in tableHeadersList"
              :key="header.name"
            >
              <div
                v-if="
                  header.name === 'ticketTable.status' ||
                    header.name === 'ticketTable.priority'
                "
                :class="[
                  header.name === 'ticketTable.updatedDate' ? 'py-3' : '',
                  'inline-flex sm:mt-0 cursor-pointer truncate',
                ]"
              ></div>
              <div
                v-else
                class="inline-flex sm:mt-0 cursor-pointer"
                @click="
                  this.setSorting(header.sort, header.name),
                    (header.isSort = !header.isSort)
                "
              >
                <span
                  :class="[
                    header.name === this.activeSorting ? `underline` : '',
                    'mr-1',
                  ]"
                >
                  {{ $t(header.name) }}
                </span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  :class="[
                    header.name === this.activeSorting ? `underline` : '',
                    'ml-2 h-5 w-5',
                    header.name === this.activeSorting ? `underline` : '',
                    'ml-2 h-5 w-5',
                  ]"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    :d="[
                      this.activeSorting === header.name && header.isSort
                        ? 'M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z'
                        : this.activeSorting === header.name && !header.isSort
                        ? 'M14.707 10.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 12.586V5a1 1 0 012 0v7.586l2.293-2.293a1 1 0 011.414 0z'
                        : 'M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z',
                    ]"
                  />
                </svg>
              </div>
            </th>
            <th scope="col" class="relative px-6 py-3">
              <span class="sr-only">Actions</span>
            </th>
          </tr>
        </thead>
        <tbody class="flex-1 sm:flex-none divide-y divide-gray-200">
          <tr
            v-for="(recipient, recipientIdx) in this.tablePaginatedData"
            :key="recipient.id"
            :class="[
              recipientIdx % 2 === 0 ? 'bg-white' : `bg-gray-50`,
              'flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0 hover:bg-gray-100 text-gray-600 cursor-pointer',
            ]"
          >
            <td
              class="p-3 text-gray-900 font-medium"
              @click.prevent="
                activeTab === 'list' ? goToList(recipient.id) : ''
              "
            >
              {{ recipient.name }}
            </td>
            <td
              v-if="recipient.number"
              class="p-3 truncate"
              @click.prevent="
                activeTab === 'list' ? goToList(recipient.id) : ''
              "
            >
              {{ recipient.number }}
            </td>

            <td
              class="p-3 truncate"
              @click.prevent="
                activeTab === 'list' ? goToList(recipient.id) : ''
              "
            >
              <span v-if="this.language === 'en'">
                {{ formatDate(recipient.created_at) }}
              </span>
              <span v-if="this.language === 'fr'">
                {{ formatDateFrench(recipient.created_at) }}
              </span>
            </td>
            <td
              class="p-3 truncate"
              @click.prevent="
                activeTab === 'list' ? goToList(recipient.id) : ''
              "
            >
              <span v-if="this.language === 'en'">
                {{ formatDate(recipient.updated_at) }}
              </span>
              <span v-if="this.language === 'fr'">
                {{ formatDateFrench(recipient.updated_at) }}
              </span>
            </td>
            <td class="text-gray-900" v-if="activeTab === 'list'">
              <UpdateRecipients
                v-if="activeTab === 'list'"
                :id="recipient.id"
                :name="recipient.name"
                :activeTab="activeTab"
                :pivots="this.pivots"
                :accountId="recipient.account_id"
                @openEditForm="
                  $emit(
                    'openEditForm',
                    recipient.id,
                    recipient.name,
                    recipient.account_id
                  )
                "
                @activeBanner="$emit('activeBanner')"
                @successMsg="$emit('successMsg', $event)"
                @success="$emit('success', $event)"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="tablePaginatedData.length < 1" class="flex justify-center">
        <tr align="center">
          <td class="py-5">
            <svg
              class="mx-auto h-16 w-16 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                vector-effect="non-scaling-stroke"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 5v2m0 4v2m0 4v2M5 5a2 2 0 00-2 2v3a2 2 0 110 4v3a2 2 0 002 2h14a2 2 0 002-2v-3a2 2 0 110-4V7a2 2 0 00-2-2H5z"
              />
            </svg>
            <h3 class="mt-2 text-xl font-medium text-gray-900">
              {{ $t("warning.noDataFoundTitle") }}
            </h3>
            <p class="mt-1 text-base text-gray-500">
              {{ $t("warning.noResultFoundDescription") }}
            </p>
          </td>
        </tr>
      </div>
    </div>
  </body>
  <!-- dialog msg -->
  <ComfirmationDialog
    v-if="this.showMessage"
    :messageToShow="this.messageToShow"
    :subMessageToShow="this.subMessageToShow"
  />
  <ask-confirmation-dialog
    ref="askConfirmationDialog"
  ></ask-confirmation-dialog>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import {
  CheckCircleIcon,
  ChevronRightIcon,
  MailIcon,
  SortAscendingIcon,
  TicketIcon,
  FilterIcon,
} from "@heroicons/vue/solid";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { LockOpenIcon, LockClosedIcon } from "@heroicons/vue/outline";

import ComfirmationDialog from "../ConfirmationDialog.vue";
import AskConfirmationDialog from "../AskConfirmationDialog.vue";
import UpdateRecipients from "./UpdateRecipients.vue";

const account =
  localStorage.getItem("account") === ""
    ? this.$store.state.user.activeAccount
    : localStorage.getItem("account");

export default {
  name: "TicketTable",
  props: [
    "tablePaginatedData",
    "statuses",
    "searchStatus",
    "activeTab",
    "pivots",
  ],
  components: {
    CheckCircleIcon,
    ChevronRightIcon,
    MailIcon,
    SortAscendingIcon,
    FilterIcon,
    ComfirmationDialog,
    AskConfirmationDialog,
    TicketIcon,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    LockOpenIcon,
    LockClosedIcon,
    UpdateRecipients,
  },
  data() {
    return {
      activeSorting: "",
      account,
      status: [],
      updateTicket: false,
      showMessage: false,
      isSort: false,
      messageToShow: "",
      askConfirmation: false,
      confirmationMessage: "",
      subMessageToShow: "",
      tableHeaders: [
        { name: "name", sort: "id", isSort: false, show: true },
        {
          name: "number",
          sort: "status_id",
          isSort: false,
          show: true,
        },
        {
          name: "createdAt",
          sort: "created_at",
          isSort: false,
          show: true,
        },
        {
          name: "updatedDate",
          sort: "updated_at",
          isSort: false,
          show: true,
        },
      ],
      tableHeadersList: [
        { name: "name", sort: "id", isSort: false, show: true },

        {
          name: "createdAt",
          sort: "created_at",
          isSort: false,
          show: true,
        },
        {
          name: "updatedDate",
          sort: "updated_at",
          isSort: false,
          show: true,
        },
      ],
      ticketAssignedTo: "",
      showIdx: "",
      showPriority: false,
      assignIndex: null,
      successAssign: true,
      searchStatus: [],
      searchPriority: [],
      showHeaderSelector: false,
      removedHeaders: [],
      tableKey: false,
    };
  },
  methods: {
    setSorting(sort, header) {
      this.$emit("sorting", sort);
      this.activeSorting = header;
    },
    formatDate(date) {
      const dateCreated = new Date(date);
      const formatedDate =
        ("0" + dateCreated.getDate()).slice(-2) +
        "/" +
        ("0" + (dateCreated.getMonth() + 1)).slice(-2) +
        "/" +
        dateCreated.getFullYear() +
        " at " +
        ("0" + dateCreated.getHours()).slice(-2) +
        ":" +
        ("0" + dateCreated.getMinutes()).slice(-2);

      return formatedDate;
    },
    formatDateFrench(date) {
      const dateCreated = new Date(date);
      const formatedDate =
        ("0" + dateCreated.getDate()).slice(-2) +
        "/" +
        ("0" + (dateCreated.getMonth() + 1)).slice(-2) +
        "/" +
        dateCreated.getFullYear() +
        " à " +
        ("0" + dateCreated.getHours()).slice(-2) +
        ":" +
        ("0" + dateCreated.getMinutes()).slice(-2);

      return formatedDate;
    },

    checkStatus(id) {
      if (this.status) {
        for (let index = 0; index < this.status.length; index++) {
          if (this.status[index].id == id) {
            return this.status[index].name;
          }
        }
      }
    },

    pushSearchStatus(status) {
      if (!this.searchStatus.includes(status)) {
        this.searchStatus.push(status);
      } else {
        let index = this.searchStatus.indexOf(status);
        if (index > -1) {
          this.searchStatus.splice(index, 1);
        }
      }
      this.$emit("pushStatusSearch", status);
    },
    pushSearchPriority(priority) {
      if (!this.searchPriority.includes(priority)) {
        this.searchPriority.push(priority);
      } else {
        let index = this.searchPriority.indexOf(priority);
        if (index > -1) {
          this.searchPriority.splice(index, 1);
        }
      }
      this.$emit("pushPrioritySearch", priority);
    },
    checkStatusColor(id) {
      if (this.status) {
        for (let index = 0; index < this.status.length; index++) {
          if (this.status[index].id == id) {
            return this.status[index].color;
          }
        }
      }
    },
    checkPriority(id) {
      if (this.priority) {
        for (let index = 0; index < this.priority.length; index++) {
          if (this.priority[index].id == id) {
            return this.priority[index].name;
          }
        }
      }
    },
    sendMessage(msg, subMsg) {
      this.showMessage = true;
      this.messageToShow = msg;
      this.subMessageToShow = subMsg;
    },
    goToList(id) {
      this.$router.push(`/sms-detail/${this.account}/${id}`);
    },
  },

  mounted() {},
  computed: {
    ...mapGetters(["active", "language"]),
  },
};
</script>

<style>
.ticket_table_height {
  min-height: 200px;
}
.header_selector_menu {
  right: 0px;
  margin-top: 26px;
  z-index: 100;
}
.priority_selection {
  padding-block-start: 20px;
}
.pagination_button {
  padding: 8px;
  margin: 2px;
  border-radius: 3px;
  font-size: 1em;
  cursor: pointer;
}
html,
body {
  height: 100%;
}

@media (min-width: 640px) {
  table {
    display: inline-table !important;
  }

  thead tr:not(:first-child) {
    height: 50px;
    display: none;
  }
}
@media only screen and (max-width: 640px) {
  td {
    height: 50px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  }

  th {
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    height: 50px;
  }
}
</style>
