<template>
 <table class="min-w-full divide-y divide-gray-300">
  <thead class="bg-gray-50">
   <tr v-if="this.callqueueTable === 'Time Slots'">
    <th
     scope="col"
     :class="[
      idx === 0 ? 'py-3.5 pl-4 pr-3 sm:pl-6' : 'px-3 py-3.5 lg:table-cell',
      'text-left text-sm font-semibold text-gray-900',
     ]"
     v-for="(header, idx) in this.timeSlotTableHeaders"
     :key="idx"
    >
     <div
      class="inline-flex sm:mt-0 cursor-pointer"
      @click="this.setSortingTimeSpans(header.sort, header.name), (header.isSort = !header.isSort)"
     >
      <span :class="[header.name === this.activeSorting ? `underline` : '', 'mr-1']">
       {{ $t(header.name) }}
      </span>
      <svg
       xmlns="http://www.w3.org/2000/svg"
       :class="[header.name === this.activeSorting ? `underline` : '', 'ml-2 h-4 w-4']"
       viewBox="0 0 20 20"
       fill="currentColor"
      >
       <path
        :d="[
         this.activeSorting === header.name && header.isSort
          ? 'M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z'
          : this.activeSorting === header.name && !header.isSort
          ? 'M14.707 10.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 12.586V5a1 1 0 012 0v7.586l2.293-2.293a1 1 0 011.414 0z'
          : 'M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z',
        ]"
       />
      </svg>
     </div>
    </th>
    <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
     <span class="sr-only">Edit</span>
    </th>
   </tr>
   <tr v-else>
    <th
     scope="col"
     :class="[
      idx === 0 ? 'py-3.5 pl-4 pr-3 sm:pl-6' : 'px-3 py-3.5 lg:table-cell',
      'text-left text-sm font-semibold text-gray-900',
     ]"
     v-for="(header, idx) in this.exceptionTableHeaders"
     :key="idx"
    >
     <div
      class="inline-flex sm:mt-0 cursor-pointer"
      @click="this.setSortingExceptions(header.sort, header.name), (header.isSort = !header.isSort)"
     >
      <span :class="[header.name === this.activeSorting ? `underline` : '', 'mr-1']">
       {{ $t(header.name) }}
      </span>
      <svg
       xmlns="http://www.w3.org/2000/svg"
       :class="[header.name === this.activeSorting ? `underline` : '', 'ml-2 h-4 w-4']"
       viewBox="0 0 20 20"
       fill="currentColor"
      >
       <path
        :d="[
         this.activeSorting === header.name && header.isSort
          ? 'M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z'
          : this.activeSorting === header.name && !header.isSort
          ? 'M14.707 10.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 12.586V5a1 1 0 012 0v7.586l2.293-2.293a1 1 0 011.414 0z'
          : 'M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z',
        ]"
       />
      </svg>
     </div>
    </th>

    <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
     <span class="sr-only">Edit</span>
    </th>
   </tr>
  </thead>
  <tbody class="divide-y divide-gray-200 bg-white">
   <tr v-for="callQueue in this.tablePaginatedData" :key="callQueue.id">
    <td v-if="this.callqueueTable === 'Time Slots'" class="px-6 py-4 whitespace-nowrap">
     <div v-if="callQueue.dayOfWeek" class="text-sm font-medium capitalize text-gray-900">
      {{ $t(this.weekdays[callQueue.dayOfWeek]) }}
     </div>

     <div v-else class="capitalize text-sm font-medium text-gray-900">
      {{ $d(callQueue.fullDate, "shortText") }}
     </div>
    </td>
    <td v-else class="px-6 py-4 whitespace-nowrap">
     <div class="capitalize text-sm font-medium text-gray-900">
      {{ $d(callQueue.day, "shortText") }}
     </div>
    </td>
    <td v-if="this.callqueueTable === 'Time Slots'" class="px-2 py-4 whitespace-nowrap">
     <div class="text-sm text-gray-900 inline-flex">
      <svg
       xmlns="http://www.w3.org/2000/svg"
       class="h-5 w-5 mr-1"
       fill="none"
       viewBox="0 0 24 24"
       stroke="currentColor"
      >
       <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
       />
      </svg>
      {{ callQueue.start.slice(0, 5) }} {{ $t("to") }}
      {{ callQueue.end.slice(0, 5) }}
     </div>
    </td>

    <td class="px-6 py-4 whitespace-nowrap">
     <div class="text-sm text-gray-900">
      {{ callQueue.reference }}
     </div>
    </td>
    <td class="px-6 py-4 whitespace-nowrap">
     <div class="text-sm text-gray-900">
      {{ callQueue.destination }}
     </div>
    </td>

    <EditCallQueueDetail
     v-if="this.callqueueTable === 'Time Slots'"
     :id="callQueue.id"
     v-on:deleteList="
      this.deleteTimeSlotList(
       callQueue.id,
       $t('timeSlotDeletedSuccessfully'),
       $t('deleteTimeSlot', {
        type: callQueue.dayOfWeek
         ? $t(this.weekdays[callQueue.dayOfWeek])
         : $d(callQueue.fullDate, 'shortText'),
       }),
       $t('wishToContinue'),
       $t('delete'),
       $t('cancel')
      )
     "
     @openEditForm="$emit('openEditFormTimeSlots', callQueue)"
    />
    <EditCallQueueDetail
     v-else
     :dayOfWeek="callQueue.dayOfWeek"
     title="Update Exceptions"
     :callQueueGroupExceptionId="callQueue.id"
     v-on:deleteList="
      this.deleteExceptionList(
       callQueue.id,
       $t('exceptionDeletedSuccessfully'),
       $t('deleteException', {
        type: $d(callQueue.day, 'shortText'),
       }),
       $t('wishToContinue'),
       $t('delete'),
       $t('cancel')
      )
     "
     @openEditForm="
      $emit('openEditFormExceptions', callQueue.id, callQueue.reference, callQueue.destination)
     "
    />
   </tr>
  </tbody>
 </table>

 <ask-confirmation-dialog ref="askConfirmationDialog"></ask-confirmation-dialog>
 <ComfirmationDialog
  v-if="this.showMessage"
  :messageToShow="this.messageToShow"
  :subMessageToShow="this.subMessageToShow"
 />
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import EditCallQueueDetail from "./EditCallQueueDetail.vue";
import AskConfirmationDialog from "./AskConfirmationDialog.vue";
import ComfirmationDialog from "./ConfirmationDialog.vue";

export default {
 name: "CallQueuesGroupsDetailTable",
 props: ["tablePaginatedData", "account", "id", "callqueueTable"],
 components: {
  Loading,
  AskConfirmationDialog,
  EditCallQueueDetail,
  ComfirmationDialog,
 },
 data() {
  return {
   timeSlotTableHeaders: [
    {
     name: "callqueueTable.weekdayAndDate",
     sort: ["dayOfWeek", "fullDate"],
     isSort: false,
    },
    { name: "callqueueTable.timeslot", sort: "start", isSort: false },
    { name: "callqueueTable.reference", sort: "reference", isSort: false },
    { name: "destination", sort: "destination", isSort: false },
   ],
   exceptionTableHeaders: [
    { name: "callqueueTable.weekdayAndDate", sort: "day", isSort: false },
    { name: "callqueueTable.reference", sort: "reference", isSort: false },
    { name: "destination", sort: "destination", isSort: false },
   ],
   weekdays: [
    "",
    "weekdays.mon",
    "weekdays.tue",
    "weekdays.wed",
    "weekdays.thu",
    "weekdays.fri",
    "weekdays.sat",
    "weekdays.sun",
   ],
   openTimeSlotForm: false,
   openExceptionForm: false,
   openBankHolidaysForm: false,
   showMessage: false,
   isLoading: true,
   fullPage: false,
  };
 },
 methods: {
  async deleteTimeSlotList(id, msg, title, confirmationMessage, confirmButton, goBack) {
   const options = {
    method: "DELETE",
    url: `${this.$cookie.getCookie("API")}/api/v1/callQueueGroupTimeSpans/${id}`,
    params: { customerAccount: this.account },
    headers: {
     "Content-Type": "application/json",
     Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
   };
   const r = await this.$refs.askConfirmationDialog.show({
    title: title,
    message: confirmationMessage,
    okButton: confirmButton,
    cancelButton: goBack,
    action: "delete",
   });
   if (r) {
    axios
     .request(options)
     .then((response) => {
      this.$emit("activeBanner");
      this.$emit("success", true);
      this.$emit("successMsg", msg);
     })
     .catch((error) => {
      this.errorHandling(error);
     });
   }
  },
  async deleteExceptionList(id, msg, title, confirmationMessage, confirmButton, goBack) {
   const options = {
    method: "DELETE",
    url: `${this.$cookie.getCookie("API")}/api/v1/callQueueGroupExceptions/${id}`,
    params: { customerAccount: this.account },
    headers: {
     "Content-Type": "application/json",
     Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
   };
   const r = await this.$refs.askConfirmationDialog.show({
    title: title,
    message: confirmationMessage,
    okButton: confirmButton,
    cancelButton: goBack,
    action: "delete",
   });
   if (r) {
    axios
     .request(options)
     .then((response) => {
      this.$emit("activeBanner");
      this.$emit("success", true);
      this.$emit("successMsg", msg);
     })
     .catch((error) => {
      this.errorHandling(error);
     });
   }
  },
  formatDate(date) {
   const dateCreated = new Date(date);
   const formatedDate =
    ("0" + dateCreated.getDate()).slice(-2) +
    "/" +
    ("0" + (dateCreated.getMonth() + 1)).slice(-2) +
    "/" +
    dateCreated.getFullYear();

   return formatedDate;
  },
  formatDateFrench(date) {
   const dateCreated = new Date(date);
   return dateCreated.toLocaleDateString("fr-FR");
  },
  fullFormatDateFrench(date) {
   const dateCreated = new Date(date);
   const frFormatDate = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
   };
   const formatDate = dateCreated.toLocaleDateString("fr-FR", frFormatDate);
   const capitalizeformatDate = formatDate.replace(/\w\S*/g, (w) =>
    w.replace(/^\w/, (c) => c.toUpperCase())
   );
   return capitalizeformatDate;
  },
  sendMessage(msg, subMsg) {
   this.showMessage = true;
   this.messageToShow = msg;
   this.subMessageToShow = subMsg;
  },
  closeTimeSlotForm() {
   this.openTimeSlotForm = !this.openTimeSlotForm;
  },
  closeExceptionForm() {
   if (this.openExceptionForm != false) {
    this.openExceptionForm = !this.openExceptionForm;
   } else {
    this.openBankHolidaysForm = !this.openBankHolidaysForm;
   }
  },
  setSortingTimeSpans(sort, header) {
   this.$emit("sortTimeSpans", sort);
   this.activeSorting = header;
  },
  setSortingExceptions(sort, header) {
   this.$emit("sortExceptions", sort);
   this.activeSorting = header;
  },
 },

 computed: {
  ...mapGetters(["language"]),
 },
};
</script>

<style></style>
