<template>
  <div class="bg-white overflow-hidden" v-if="this.previewData.fails[0]">
    <div class="relative bg-red-600">
      <div class="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
        <div class="pr-0 sm:text-center sm:px-0">
          <p class="font-medium text-white">
            <span class="hidden md:inline">
              {{ $t("contactUploadErrorTitle") }}.
            </span>
          </p>
        </div>
      </div>
    </div>
    <ul class="divide-y divide-gray-200">
      <li
        class="px-2 py-1 sm:px-2 text-red-500"
        v-for="fail in this.previewData.fails"
        :key="fail.id"
      >
        {{ fail.status_long }}
      </li>
    </ul>
    <div :class="`relative bg-red-100`">
      <div class="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
        <div class="pr-16 sm:text-center sm:px-16">
          <p :class="`font-medium text-red-800`">
            <span class="hidden md:inline">
              {{ $t("contactUploadErrorMsg") }}?
            </span>
          </p>
          <button
            v-if="previewTableType === 'contacts'"
            @click="sendContacts"
            type="button"
            :class="
              `rounded inline-flex ml-0 items-center px-3 py-1 border border-transparent text-sm font-medium shadow-sm text-white bg-blue-500 hover:bg-blue-600 focus:outline-none`
            "
          >
            {{ $t("confirm") }}
          </button>
          <button
            v-if="previewTableType === 'cti-destination'"
            @click="sendDestinations"
            type="button"
            :class="
              `rounded inline-flex ml-0 items-center px-3 py-1 border border-transparent text-sm font-medium shadow-sm text-white bg-blue-500 hover:bg-blue-600 focus:outline-none`
            "
          >
            {{ $t("confirm") }}
          </button>
          <button
            @click="goBack"
            type="button"
            :class="
              `rounded inline-flex ml-5 items-center px-3 py-1 border border-transparent text-sm font-medium shadow-sm text-red-100 bg-red-500 hover:bg-red-600 focus:outline-none`
            "
          >
            {{ $t("goBack") }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="bg-white overflow-hidden" v-if="!this.previewData.fails[0]">
    <div class="relative bg-green-600">
      <div class="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
        <div class="pr-0 sm:text-center sm:px-0">
          <p class="font-medium text-white">
            <span class="hidden md:inline">
              {{ $t("contactTablePreviewNoProblemsMsg") }}.
            </span>
          </p>
        </div>
      </div>
    </div>
    <div :class="`relative bg-green-100`">
      <div class="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
        <div class="pr-16 sm:text-center sm:px-16">
          <p :class="`font-medium text-green-800`">
            <span class="hidden md:inline">
              {{ $t("contactTablePreviewConfirmationMsg") }}?
            </span>
          </p>
          <div class="pt-2">
            <button
              @click="goBack"
              type="button"
              :class="
                `rounded inline-flex ml-0 items-center px-3 py-1 border border-transparent text-sm font-medium shadow-sm text-red-100 bg-red-500 hover:bg-red-600 focus:outline-none`
              "
            >
              {{ $t("goBack") }}
            </button>
            <button
              v-if="previewTableType === 'contacts'"
              @click="sendContacts"
              type="button"
              :class="
                `rounded inline-flex ml-5 items-center px-3 py-1 border border-transparent text-sm font-medium shadow-sm text-white bg-blue-500 hover:bg-blue-600 focus:outline-none`
              "
            >
              {{ $t("confirm") }}
            </button>
            <button
              v-if="previewTableType === 'cti-destination'"
              @click="sendDestinations"
              type="button"
              :class="
                `rounded inline-flex ml-5 items-center px-3 py-1 border border-transparent text-sm font-medium shadow-sm text-white bg-blue-500 hover:bg-blue-600 focus:outline-none`
              "
            >
              {{ $t("confirm") }}
            </button>
            <button
              v-if="previewTableType === 'recipients'"
              @click="sendNumbers"
              type="button"
              :class="
                `rounded inline-flex ml-5 items-center px-3 py-1 border border-transparent text-sm font-medium shadow-sm text-white bg-blue-500 hover:bg-blue-600 focus:outline-none`
              "
            >
              {{ $t("confirm") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div :class="`relative main_header mt-10`">
    <div class="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
      <div class="pr-16 sm:text-center sm:px-16">
        <p :class="`font-medium text-white`">
          <span class="hidden md:inline capitalize">
            {{ $t("tablePreview") }}
          </span>
        </p>
      </div>
    </div>
  </div>
  <table
    class="w-full sm:bg-white overflow-hidden my-0 table-fixed"
  >
    <thead :class="`bg-gray-50 divide-y divide-gray-200`">
      <tr
        class="flex flex-col flex-wrap sm:table-row sm:rounded-none mb-2 sm:mb-0"
      >
        <th
          scope="col"
          :class="`p-3 text-left capitalize text-sm`"
          v-for="header in previewTableHeader"
          :key="header"
        >
          {{ header }}
        </th>
      </tr>
    </thead>
    <tbody
      class="flex-1 sm:flex-none divide-y divide-gray-200"
      v-if="previewTableType === 'contacts'"
    >
      <tr
        v-for="(route, idx) in this.previewDataPaginated"
        :key="route.id"
        :class="[
          idx % 2 === 0 ? 'bg-white' : `bg-gray-50`,
          'flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0 hover:bg-gray-100 cursor-pointer',
        ]"
      >
        <td :class="`p-3`">
          {{ route.companyName }}
        </td>
        <td :class="`p-3 truncate`">
          <span v-if="route.firstName"> {{ route.firstName }} </span>
          {{ route.lastName }}
        </td>
        <td :class="`p-3 truncate uppercase`">
          <span v-if="route.vip == 1">{{ $t("yes") }}</span>
          <span v-if="route.vip == 0">{{ $t("no") }}</span>
        </td>
        <td :class="`p-3 truncate uppercase`">
          <span v-if="route.onCallDuty == 1">{{ $t("yes") }}</span>
          <span v-if="route.onCallDuty == 0">{{ $t("no") }}</span>
        </td>
        <td :class="`p-3 truncate`">
          {{ route.code }}
        </td>
        <td :class="`p-3`">
          <ul
            v-for="contact in this.contactsArray(route.contacts)"
            :key="contact"
          >
            <li>
              {{ contact }}
            </li>
          </ul>
        </td>
      </tr>
    </tbody>
    <tbody
      class="flex-1 sm:flex-none divide-y divide-gray-200"
      v-if="previewTableType === 'cti-destination'"
    >
      <tr
        v-for="(destination, idx) in this.previewDataPaginated"
        :key="destination.id"
        :class="[
          idx % 2 === 0 ? 'bg-white' : `bg-gray-50`,
          'flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0 hover:bg-gray-100 cursor-pointer',
        ]"
      >
        <td :class="`p-3 truncate`">
          {{ destination.origin }}
        </td>
        <td :class="`p-3`">
          {{ destination.destination }}
        </td>
        <td :class="`p-3 truncate`">
          {{ destination.updated_at }}
        </td>
      </tr>
    </tbody>
    <tbody
      class="flex-1 sm:flex-none divide-y divide-gray-200"
      v-if="previewTableType === 'recipients'"
    >
      <tr
        v-for="(recipient, idx) in this.previewDataPaginated"
        :key="recipient.id"
        :class="[
          idx % 2 === 0 ? 'bg-white' : `bg-gray-50`,
          'flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0 hover:bg-gray-100 cursor-pointer',
        ]"
      >
        <td :class="`p-3 truncate`">
          {{ recipient.number }}
        </td>
      </tr>
    </tbody>
  </table>

  <loading
    v-model:active="isLoading"
    :can-cancel="false"
    :on-cancel="onCancel"
    :is-full-page="fullPage"
  />
</template>

<script>
import axios from "axios";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import { mapGetters } from "vuex";

const account = localStorage.getItem("account");

export default {
  name: "ContactTablePreview",
  components: {
    Loading,
  },
  props: [
    "previewData",
    "previewDataPaginated",
    "previewTableHeader",
    "previewTableType",
    "id",
  ],
  data() {
    return {
      isLoading: false,
      fullPage: true,
      account,
    };
  },
  methods: {
    sendDestinations() {
      this.isLoading = true;
      const options = {
        method: "POST",
        url: `${this.$cookie.getCookie(
          "API"
        )}/api/v1/ctiDestinations/confirmation`,
        params: {
          customerAccount: this.account,
          cti_id: this.id,
          operation_key: this.previewData.operation_key,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      axios
        .request(options)
        .then((res) => {
          location.reload();
        })
        .catch((error) => {
          this.errorHandling(error);
          this.isLoading = false;
        });
    },
    sendContacts() {
      this.isLoading = true;
      const options = {
        method: "POST",
        url: `${this.$cookie.getCookie("API")}/api/v1/contacts/confirmation`,
        params: {
          customerAccount: this.account,
          operation_key: this.previewData.operation_key,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      axios
        .request(options)
        .then((res) => {
          location.reload();
        })
        .catch((error) => {
          this.errorHandling(error);
          this.isLoading = false;
        });
    },
    sendNumbers() {
      this.isLoading = true;
      const options = {
        method: "POST",
        url: `${this.$cookie.getCookie(
          "API"
        )}/api/v1/sms/recipients/confirmation`,
        params: {
          customerAccount: this.account,
          operation_key: this.previewData.operation_key,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      axios
        .request(options)
        .then((res) => {
          location.reload();
        })
        .catch((error) => {
          this.errorHandling(error);
          this.isLoading = false;
        });
    },
    contactsArray(contacts) {
      let array = String(contacts).split(",");
      return array;
    },
    goBack() {
      location.reload();
    },
  },
};
</script>

<style>
.contact_table {
  overflow-x: hidden;
  overflow-y: hidden;
}
</style>
