<template>
  <div class="bg-white" :key="this.showChart">
    <ul class="divide-y divide-gray-200">
      <li v-for="levelNumber in this.tablePaginatedData" :key="levelNumber">
        <a
          href="javascript:void(0)"
          @click="
            this.$router.push(
              `/expert-statistics/${levelNumber.type}/${levelNumber.value}`
            )
          "
          class="block hover:bg-gray-50"
        >
          <div class="px-4 py-4 flex items-center sm:px-6">
            <div
              class="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between"
            >
              <div class="inline-flex">
                <div class="flex text-sm">
                  <p :class="`font-medium truncate encom_dark_text `">
                    {{ levelNumber.value }}
                  </p>
                </div>

                <div class="ml-10 flex">
                  <div class="flex items-center text-sm text-gray-500">
                    <p>
                      {{ levelNumber.label }}
                    </p>
                  </div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 ml-3 text-gray-500"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div>
              <ChevronRightIcon
                class="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
          </div>
        </a>
      </li>
    </ul>
    <div v-if="tablePaginatedData.length < 1" class="text-center p-5">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="mx-auto h-10 w-10 text-gray-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
        />
      </svg>
      <h3 class="mt-2 text-xl font-medium text-gray-900">
        {{ $t("warning.noDataFoundTitle") }}
      </h3>
      <p class="mt-1 text-base text-gray-500">
        {{ $t("warning.noResultFoundDescription") }}
      </p>
    </div>
  </div>
</template>

<script>
import { ChevronRightIcon, ChevronDownIcon } from "@heroicons/vue/solid";

export default {
  name: "PBXLevelList",
  props: ["level", "tablePaginatedData"],
  components: {
    ChevronRightIcon,
    ChevronDownIcon,
  },
  setup() {
    return {
      showChart: false,
      showIdx: "",
    };
  },
  methods: {
    setShowChart(idx) {
      // check if the index of the item on the list is different from the one that is active, and if the chart is opened
      // when clicked - if the index is not the one active - it will open the one that was clicked and close the one that was active
      if (this.showIdx !== "" && idx != this.showIdx && this.showChart) {
        this.showIdx = idx;
        this.showChart = true;
      } else {
        this.showChart = !this.showChart;
        this.showIdx = idx;
      }

      this.$forceUpdate();
    },
  },
};
</script>
