<template>
 <div class="flex flex-col">
  <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
   <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
    <div class="overflow-hidden sm:rounded-t-md">
     <div>
      <table class="w-full flex flex-row flex-no-wrap sm:bg-white overflow-hidden my-0 table-fixed">
       <thead class="bg-gray-50 divide-y divide-gray-200">
        <tr class="flex flex-col flex-wrap sm:table-row sm:rounded-none mb-2 sm:mb-0">
         <th
          scope="col"
          class="p-3 text-left text-sm"
          v-for="header in this.tableHeaders"
          :key="header.name"
         >
          <div
           class="inline-flex lg:mt-1 xl:mt-1 sm:mt-0 cursor-pointer"
           @click="
            header.sort
             ? this.setSorting(header.sort, header.name) & (header.isSort = !header.isSort)
             : ''
           "
          >
           <span :class="[header.name === this.activeSorting ? `underline` : '', 'mr-1']">
            {{ $t(header.name) }}
           </span>
           <svg
            v-if="header.sort"
            xmlns="http://www.w3.org/2000/svg"
            :class="[header.name === this.activeSorting ? `underline` : '', 'ml-2 h-5 w-5']"
            viewBox="0 0 20 20"
            fill="currentColor"
           >
            <path
             :d="[
              this.activeSorting === header.name && header.isSort
               ? 'M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z'
               : this.activeSorting === header.name && !header.isSort
               ? 'M14.707 10.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 12.586V5a1 1 0 012 0v7.586l2.293-2.293a1 1 0 011.414 0z'
               : 'M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z',
             ]"
            />
           </svg>
          </div>
         </th>
         <!-- <th scope="col" class="relative px-6 py-3">
                    <span class="sr-only">Actions</span>
                  </th> -->
        </tr>
       </thead>
       <tbody
        v-show="this.tablePaginatedData.length > 0"
        class="flex-1 sm:flex-none divide-y divide-gray-200"
       >
        <tr
         v-for="(campaign, idx) in this.tablePaginatedData"
         :key="campaign.id"
         :class="[
          idx % 2 === 0 ? 'bg-white' : `bg-gray-50`,
          'flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0 hover:bg-gray-100 text-gray-600 cursor-pointer',
         ]"
         @click="this.$router.push(`/sms-campaign/${activeTab}/${campaign.idSend}`)"
        >
         <td class="p-3 text-gray-900 font-medium">
          {{ campaign.idSend }}
         </td>
         <td class="p-3 truncate" :title="campaign.message">
          {{ campaign.message }}
         </td>
         <td class="p-3">
          <span
           :class="[
            campaign.type === 'm' ? 'bg-green-100 text-green-800' : 'bg-blue-100 text-blue-800',
            'capitalize inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium',
           ]"
          >
           {{ campaign.type === "m" ? $t("yes") : $t("no") }}
          </span>
         </td>
         <td class="p-3">
          <span
           :class="[
            campaign.sent === 1 ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800',
            'capitalize inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium',
           ]"
          >
           {{ campaign.sent === 1 ? $t("yes") : $t("no") }}
          </span>
         </td>
         <td class="p-3">
          {{ formatDateFrench(campaign.created_at) }}
         </td>
         <td class="p-3">
          {{ formatDateFrench(campaign.updated_at) }}
         </td>
         <!-- <td class="text-gray-900">
                    <UpdateCampaigns
                      :id="campaign.id"
                      :name="campaign.name"
                      @openEditForm="
                        $emit('openEditForm', campaign.id, campaign.name)
                      "
                      @activeBanner="$emit('activeBanner')"
                      @successMsg="$emit('successMsg', $event)"
                      @success="$emit('success', $event)"
                    />
                  </td> -->
        </tr>
       </tbody>
      </table>
      <div v-show="this.tablePaginatedData.length < 1" class="text-center py-5">
       <svg
        class="mx-auto h-16 w-16 text-gray-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
       >
        <path
         vector-effect="non-scaling-stroke"
         stroke-linecap="round"
         stroke-linejoin="round"
         stroke-width="2"
         d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
        />
       </svg>

       <h3 class="mt-2 text-xl font-medium text-gray-900">No Results</h3>
       <p class="mt-1 text-base text-gray-500">
        You don't have any results for this filter.
       </p>
      </div>
     </div>
    </div>
   </div>
  </div>
 </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import UpdateCampaigns from "./UpdateCampaigns.vue";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
 name: "CampaignsTable",
 props: [
  "account",
  "activeTab",
  "searchMonth",
  "searchYear",
  "searchNumb",
  "searchTotalWithTax",
  "searchTotalWithoutTax",
  "tablePaginatedData",
 ],
 components: {
  Loading,
  UpdateCampaigns,
 },
 data() {
  return {
   tableHeaders: [
    { name: "campaign", sort: "id", isSort: false },
    { name: "content", sort: null, isSort: false },
    {
     name: "marketing",
     sort: null,
     isSort: false,
    },
    {
     name: "ready",
     sort: null,
     isSort: false,
    },
    {
     name: "createdAt",
     sort: "id",
     isSort: false,
    },
    {
     name: "updatedDate",
     sort: "id",
     isSort: false,
    },
   ],
   isLoading: true,
   fullPage: false,
   activeSorting: "",
  };
 },
 methods: {
  setSorting(sort, header) {
   this.$emit("sort", sort);
   this.activeSorting = header;
  },

  getYear(date) {
   const dateCreated = new Date(date);
   const formatedDate = dateCreated.getFullYear();

   return String(formatedDate);
  },
  getMonth(date) {
   const dateCreated = new Date(date);
   const formatedDate = ("0" + (dateCreated.getMonth() + 1)).slice(-2);
   return String(formatedDate);
  },
  frenchAmount(amount) {
   let splitedAmount = amount.split(".");
   splitedAmount[0] = splitedAmount[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
   let newFormat = splitedAmount[0] + "," + splitedAmount[1];
   return newFormat;
  },
  formatDate(date) {
   const dateCreated = new Date(date);
   const formatedDate =
    ("0" + dateCreated.getDate()).slice(-2) +
    "/" +
    ("0" + (dateCreated.getMonth() + 1)).slice(-2) +
    "/" +
    dateCreated.getFullYear();

   return formatedDate;
  },
  formatDateFrench(date) {
   const dateCreated = new Date(date);
   const formatedDate =
    ("0" + dateCreated.getDate()).slice(-2) +
    "/" +
    ("0" + (dateCreated.getMonth() + 1)).slice(-2) +
    "/" +
    dateCreated.getFullYear();

   return formatedDate;
  },
 },
 mounted() {},
 computed: {
  ...mapGetters(["language"]),
 },
};
</script>

<style>
html,
body {
 height: 100%;
}

@media (min-width: 640px) {
 table {
  display: inline-table !important;
 }

 thead tr:not(:first-child) {
  height: 40px;
  display: none;
 }
}
@media only screen and (max-width: 640px) {
 td {
  height: 40px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
 }

 th {
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  height: 40px;
 }
}
</style>
