<template>
  <div v-if="String(this.showIdx) === String(this.idx) && this.showList">
    <ReportDetail
      @close="this.showList = $event"
      :details="this.reportDetail"
      :parameters="this.reportDetailParameters"
    />
  </div>
  <div class="shadow rounded-lg">
    <table class="min-w-full divide-y divide-gray-300">
      <thead class="bg-gray-50">
        <tr class="">
          <th
            scope="col"
            v-for="(header, idx) in this.tableHeaders"
            :class="[
              idx === 0
                ? 'py-3.5 pl-4 pr-3 sm:pl-6'
                : 'px-3 py-3.5 lg:table-cell',
              'text-left text-sm font-semibold text-gray-900 capitalize',
            ]"
            :key="header.name"
          >
            <div
              class="inline-flex lg:mt-1 xl:mt-1 sm:mt-0 cursor-pointer"
              @click="
                this.setSorting(header.sort, header.name),
                  (header.isSort = !header.isSort)
              "
            >
              <span
                :class="[
                  header.name === this.activeSorting ? 'underline' : '',
                  'mr-1',
                ]"
              >
                {{ $t(header.name) }}
              </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                :class="[
                  header.name === this.activeSorting ? 'underline' : '',
                  'ml-2 h-5 w-5',
                ]"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  :d="[
                    this.activeSorting === header.name && header.isSort
                      ? 'M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z'
                      : this.activeSorting === header.name && !header.isSort
                      ? 'M14.707 10.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 12.586V5a1 1 0 012 0v7.586l2.293-2.293a1 1 0 011.414 0z'
                      : 'M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z',
                  ]"
                />
              </svg>
            </div>
          </th>
          <th scope="col" class="relative px-6 py-3">
            <span class="sr-only">Actions</span>
          </th>
        </tr>
      </thead>
      <tbody
        v-show="this.tablePaginatedData.length > 0"
        class="flex-1 sm:flex-none divide-y divide-gray-200"
      >
        <tr
          v-for="(report, idx) in this.tablePaginatedData"
          :key="report.id"
          :class="[
            idx % 2 === 0 ? 'bg-white' : `bg-gray-50`,
            'text-sm flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0 hover:bg-gray-100 text-gray-600',
          ]"
        >
          <td
            @click="getReportDetail(report.id, idx)"
            class="p-3 text-base text-gray-900 font-medium cursor-pointer"
          >
            {{ report.nickname }}
          </td>

          <td
            @click="getReportDetail(report.id, idx)"
            class="p-3 truncate cursor-pointer"
            :title="report.email_addresses"
          >
            {{ report.email_addresses }}
          </td>
          <td
            @click="getReportDetail(report.id, idx)"
            class="p-3 cursor-pointer"
          >
            <span
              :class="
                report.cron
                  ? 'bg-green-100 text-green-800 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium'
                  : ''
              "
            >
              {{ $t(isSchedule(report.cron)) }}
            </span>
          </td>
          <td
            @click="getReportDetail(report.id, idx)"
            class="capitalize p-3 cursor-pointer"
          >
            {{ toLocaleDateString(report.created_at) }}
          </td>
          <td
            @click="getReportDetail(report.id, idx)"
            class="p-3 cursor-pointer"
          >
            {{ toLocaleTimeString(report.updated_at) }}
          </td>

          <td class="text-gray-900">
            <EditReportList
              v-on:deleteList="
                this.deleteList(
                  report.id,
                  'Report deleted successfully',
                  `Delete report '${report.nickname}'`,
                  'Do you wish to continue?',
                  'Continue',
                  'Go back'
                )
              "
              @openEditForm="
                $emit(
                  'openEditForm',
                  report.id,
                  report.nickname,
                  report.parameters,
                  report.email_addresses,
                  report.cron
                )
              "
              @activeBanner="$emit('activeBanner')"
              @successMsg="$emit('successMsg', $event)"
              @success="$emit('success', $event)"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <ask-confirmation-dialog
    ref="askConfirmationDialog"
  ></ask-confirmation-dialog>
</template>

<script>
import axios from "axios";
import EditReportList from "./EditReportList.vue";
import AskConfirmationDialog from "../../components/AskConfirmationDialog.vue";
import ReportDetail from "./ReportDetail.vue";
const account = localStorage.getItem("account");

export default {
  name: "ReportListTable",
  props: ["searchName", "searchToken", "tablePaginatedData", "elementsData"],
  data() {
    return {
      account,
      tableHeaders: [
        { name: "name", sort: "nickname", isSort: false },
        { name: "email", sort: "email_addresses", isSort: false },
        { name: "schedule", sort: "cron", isSort: false },
        { name: "createdAt", sort: "created_at", isSort: false },
        { name: "updatedAt", sort: "updated_at", isSort: false },
      ],
      closeForm: true,
      showList: false,
      showIdx: "",
      idx: null,
      reportDetail: [],
      reportDetailParameters: "",
      activeSorting: "",
    };
  },
  components: { EditReportList, ReportDetail, AskConfirmationDialog },
  methods: {
    async deleteList(
      id,
      msg,
      title,
      confirmationMessage,
      confirmButton,
      goBack
    ) {
      const options = {
        method: "DELETE",
        url: `${this.$cookie.getCookie("API")}/api/v1/presentations/${id}`,

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const r = await this.$refs.askConfirmationDialog.show({
        title: title,
        message: confirmationMessage,
        okButton: confirmButton,
        cancelButton: goBack,
        action: "delete",
      });
      if (r) {
        axios
          .request(options)
          .then((response) => {
            this.$emit("activeBanner");
            this.$emit("success", true);
            this.$emit("successMsg", msg);
          })
          .catch((error) => {
            this.errorHandling(error);
            this.$emit("activeBanner");
            this.$emit("success", false);
            this.$emit("successMsg", err.response.data.message);
          });
      }
    },
    async getReportDetail(...args) {
      let id = args[0];
      this.setshowList(args[1]);
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/presentations/${id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        this.isLoading = false;
        this.reportDetail = res.data;
        this.reportDetailParameters = JSON.parse(res.data.parameters);
      } catch (err) {
        this.isLoading = false;
        //this.errorHandling(err);
        console.error(err);
      }
    },
    setshowList(idx) {
      this.idx = idx;
      // check if the index of the item on the list is different from the one that is active, and if the chart is opened
      // when clicked - if the index is not the one active - it will open the one that was clicked and close the one that was active
      if (this.showIdx !== "" && idx != this.showIdx && this.showList) {
        this.showIdx = this.idx;
        this.showList = true;
      } else {
        this.showList = !this.showList;
        this.showIdx = this.idx;
      }
      this.$forceUpdate();
    },
    setSorting(sort, header) {
      this.$emit("sort", sort);
      this.activeSorting = header;
    },
    cancelForm() {
      this.closeForm = !this.closeForm;
      this.$forceUpdate();
    },
    formatDateFrench(date) {
      const dateCreated = new Date(date);
      const formatedDate =
        ("0" + dateCreated.getDate()).slice(-2) +
        "/" +
        ("0" + (dateCreated.getMonth() + 1)).slice(-2) +
        "/" +
        dateCreated.getFullYear();

      return formatedDate;
    },

    isSchedule(cron) {
      if (cron) {
        if (cron === "0 4 * * *") {
          return "daily";
        } else if (cron === "0 4 * * 1") {
          return "weekly";
        } else if (cron === "0 4 1 * *") {
          return "monthly";
        }
      } else {
        return "One-shot";
      }
    },
  },
};
</script>

<style></style>
