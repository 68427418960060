<template>
 <div class="mt-8 flow-root">
  <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
   <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
    <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
     <table class="min-w-full divide-y divide-gray-300">
      <thead class="bg-gray-50">
       <tr>
        <th
         v-for="(header, index) in thead"
         :key="index"
         scope="col"
         :class="{ 'pl-4 pr-3 sm:pl-6': index === 0, 'px-3': index !== 0 }"
         class="py-3.5 text-left"
        >
         <button
          @click="setSorting(header.sort, header.name)"
          class="text-sm font-semibold text-gray-900 group inline-flex"
         >
          {{ $t(header.name) }}

          <span
           v-if="header.isSort && header.sort"
           class="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300"
          >
           <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            class="w-5 h-5"
           >
            <path
             fill-rule="evenodd"
             d="M14.77 12.79a.75.75 0 01-1.06-.02L10 8.832 6.29 12.77a.75.75 0 11-1.08-1.04l4.25-4.5a.75.75 0 011.08 0l4.25 4.5a.75.75 0 01-.02 1.06z"
             clip-rule="evenodd"
            />
           </svg>
          </span>

          <span
           v-else-if="header.sort"
           class="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300"
          >
           <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            class="w-5 h-5"
           >
            <path
             fill-rule="evenodd"
             d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
             clip-rule="evenodd"
            />
           </svg>
          </span>
         </button>
        </th>
        <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
         <span class="sr-only">Edit</span>
        </th>
       </tr>
      </thead>

      <tbody class="divide-y divide-gray-200 bg-white">
       <tr
        v-for="data in pagination().querySet"
        :key="data.id"
        :class="{
         'cursor-pointer':
          userPermissions.permissions.includes('smart-routings.*') ||
          userPermissions.permissions.includes('smart-routings.view'),
        }"
        class="hover:bg-gray-50"
        @click="
         userPermissions.permissions.includes('smart-routings.*') ||
         userPermissions.permissions.includes('smart-routings.view')
          ? this.$router.push(`/callqueues-groups/${this.account}/${data.id}`)
          : ''
        "
       >
        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
         {{ data.name }}
        </td>

        <td class="px-6 py-6 whitespace-nowrap text-right text-sm font-medium flex justify-end">
         <span class="isolate inline-flex rounded-md shadow-sm">
          <button
           v-show="
            userPermissions.permissions.includes('smart-routings.*') ||
            userPermissions.permissions.includes('smart-routings.edit')
           "
           type="button"
           @click.stop="$emit('openEditForm', data)"
           :class="[
            userPermissions.permissions.includes('smart-routings.*') ||
            userPermissions.permissions.includes('smart-routings.delete')
             ? 'rounded-l-md'
             : 'rounded-md',
            'relative -ml-px inline-flex items-center bg-white px-2 py-1 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10',
           ]"
          >
           <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            class="w-5 h-5 text-gray-700"
           >
            <path
             d="M5.433 13.917l1.262-3.155A4 4 0 017.58 9.42l6.92-6.918a2.121 2.121 0 013 3l-6.92 6.918c-.383.383-.84.685-1.343.886l-3.154 1.262a.5.5 0 01-.65-.65z"
            />
            <path
             d="M3.5 5.75c0-.69.56-1.25 1.25-1.25H10A.75.75 0 0010 3H4.75A2.75 2.75 0 002 5.75v9.5A2.75 2.75 0 004.75 18h9.5A2.75 2.75 0 0017 15.25V10a.75.75 0 00-1.5 0v5.25c0 .69-.56 1.25-1.25 1.25h-9.5c-.69 0-1.25-.56-1.25-1.25v-9.5z"
            />
           </svg>
          </button>
          <button
           v-show="
            userPermissions.permissions.includes('smart-routings.*') ||
            userPermissions.permissions.includes('smart-routings.delete')
           "
           type="button"
           @click.stop="$emit('deleteList', data.id, data.name)"
           :class="[
            userPermissions.permissions.includes('smart-routings.*') ||
            userPermissions.permissions.includes('smart-routings.edit') ||
            userPermissions.permissions.includes('smart-routings.create')
             ? 'rounded-r-md'
             : 'rounded-md',
            'hover:bg-red-50 hover:text-red-500 hover:ring-inset hover:ring-1 hover:ring-red-500 text-gray-700 relative -ml-px inline-flex items-center bg-white px-2 py-1 text-sm font-semibold ring-1 ring-inset ring-gray-300 focus:z-10',
           ]"
          >
           <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            class="w-5 h-5"
           >
            <path
             fill-rule="evenodd"
             d="M8.75 1A2.75 2.75 0 006 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 10.23 1.482l.149-.022.841 10.518A2.75 2.75 0 007.596 19h4.807a2.75 2.75 0 002.742-2.53l.841-10.52.149.023a.75.75 0 00.23-1.482A41.03 41.03 0 0014 4.193V3.75A2.75 2.75 0 0011.25 1h-2.5zM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4zM8.58 7.72a.75.75 0 00-1.5.06l.3 7.5a.75.75 0 101.5-.06l-.3-7.5zm4.34.06a.75.75 0 10-1.5-.06l-.3 7.5a.75.75 0 101.5.06l.3-7.5z"
             clip-rule="evenodd"
            />
           </svg>
          </button>
         </span>
        </td>
       </tr>
      </tbody>
     </table>

     <PaginationComponent
      :backend_pagination="false"
      :current_page="1"
      first_page_url="first_page_url"
      :from="this.rows * this.page - this.rows + 1"
      :last_page="10"
      last_page_url="data.last_page_url"
      links="data.links"
      next_page_url="data.next_page_url"
      :per_page="5"
      :to="getPageLength()"
      :total="this.tbody.length"
      :rows="rows"
      :select_rows="selectRows"
      @setRows="selectedRows = $event"
      @action="$emit('action', $event)"
      @previousPage="previousPage()"
      @nextPage="nextPage()"
     />
    </div>
   </div>
  </div>
 </div>
</template>

<script>
import { mapGetters } from "vuex";

import PaginationComponent from "./PaginationComponent.vue";
const account = localStorage.getItem("account");
const userPermissions = JSON.parse(localStorage.getItem("userPermissions"));

export default {
 name: "CallQueuesGroupsTable",

 props: {
  thead: {
   type: Array,
   required: true,
  },
  tbody: {
   type: Array,
   required: true,
  },
 },
 components: {
  PaginationComponent,
 },
 data() {
  return {
   account,
   page: 1,
   rows: 10,
   selectedRows: null,
   selectRows: [5, 10, 15, 20, 25, 30],
   userPermissions,
  };
 },
 methods: {
  setSorting(sort, header) {
   this.$emit("sort", sort);
   this.activeSorting = header;

   // Set the selected header's isSort value to the opposite of its current value
   const selectedHeader = this.thead.find((header) => header.sort === sort);
   selectedHeader.isSort = !selectedHeader.isSort;
   // Loop through the other headers and set their isSort value to false
   this.thead.forEach((header) => {
    if (header.sort !== sort) {
     header.isSort = false;
    }
   });
  },
  getPageLength() {
   if (this.page === this.pagination().pages) {
    return this.tbody.length;
   } else {
    return this.rows * this.page;
   }
  },
  pagination() {
   if (this.selectedRows) {
    this.rows = this.selectedRows;
   }
   let trimStart = (this.page - 1) * parseInt(this.rows);
   let trimEnd = trimStart + parseInt(this.rows);
   let trimmedData = this.tbody.slice(trimStart, trimEnd);
   let pages = Math.ceil(this.tbody.length / this.rows);
   return {
    querySet: trimmedData,
    pages: pages,
   };
  },
  nextPage() {
   if (this.page < this.pagination().pages) {
    this.page = parseInt(this.page) + 1;
   } else {
    this.page = this.page;
   }
  },
  previousPage() {
   if (this.page > 1) {
    this.page = parseInt(this.page) - 1;
   } else {
    this.page = this.page;
   }
  },
 },
 computed: {
  ...mapGetters(["language"]),
 },
};
</script>

<style></style>
