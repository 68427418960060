<template>
 <body class="items-center justify-center">
  <div>
   <table
    :class="['w-full flex flex-row flex-no-wrap sm:bg-white overflow-hidden my-0 table-fixed']"
   >
    <thead class="bg-gray-50 divide-y divide-gray-200">
     <tr
      class="flex flex-col flex-wrap sm:table-row sm:rounded-none mb-2 sm:mb-0"
      v-for="document in this.tablePaginatedData"
      :key="document.id"
     >
      <th class="p-3 text-left capitalize text-sm">
       {{ this.headerTitle }}
      </th>

      <th class="p-6 text-left" width="110px">
       <span class="sr-only">Edit</span>
      </th>
     </tr>
    </thead>
    <tbody class="flex-1 sm:flex-none divide-y divide-gray-200">
     <tr
      v-for="(document, documentIdx) in this.tablePaginatedData"
      :key="document.id"
      :class="[
       documentIdx % 2 === 0 ? 'bg-white' : `bg-gray-50`,
       'flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0 hover:bg-gray-100 cursor-pointer',
      ]"
      @click="getDocumentFile(document.id, document.mimeType, this.account)"
     >
      <td class="p-3">
       {{ document.fileName }}
      </td>
      <td class="p-3 truncate">
       <a href="#" @click="getDocumentFile(document.id, document.mimeType, this.account)"
        ><svg
         xmlns="http://www.w3.org/2000/svg"
         class="h-5 w-5 ml-5 mt-1 hover:text-malachite"
         fill="none"
         viewBox="0 0 24 24"
         stroke="currentColor"
        >
         <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
         />
         <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
         />
        </svg>
       </a>
      </td>
     </tr>
    </tbody>
   </table>
  </div>
 </body>
</template>

<script>
import { mapGetters } from "vuex";

import axios from "axios";
export default {
 props: ["tablePaginatedData", "account", "headerTitle"],
 data() {
  return {
   tableHeaders: [{ name: "Document name" }],
  };
 },
 methods: {
  async getDocumentFile(id, mimeType, account) {
   this.$emit("setLoading", true);
   const res = await axios
    .get(
     `${this.$cookie.getCookie("API")}/api/v1/documents/${id}/content?customerAccount=${account}`,
     {
      headers: {
       Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: "arraybuffer",
     }
    )

    .catch((err) => {
     console.error(err);
     this.$emit("activeBanner");
     this.$emit("success", false);
     this.$emit("successMsg", this.errorDataResponse);
    });
   const file = new Blob([await res.data], { type: mimeType });
   const fileURL = URL.createObjectURL(file);
   if (!res.data) {
    let err = this.$t("documentNotFound");
    this.$emit("activeBanner");
    this.$emit("success", false);
    this.$emit("successMsg", err);
   } else {
    window.open(fileURL);
   }
   this.$emit("setLoading", false);
  },
 },
 computed: {
  ...mapGetters(["language"]),
 },
};
</script>

<style></style>
