<template>
 <div class="">
  <table class="min-w-full divide-y divide-gray-300">
   <thead :class="`bg-gray-50`">
    <tr>
     <th
      scope="col"
      v-for="(header, idx) in tableHeaders"
      :class="[
       idx === 0 ? 'py-3.5 pl-4 pr-3 sm:pl-6' : 'px-3 py-3.5 lg:table-cell',
       'text-left text-sm font-semibold text-gray-900',
      ]"
      :key="header.name">
      <div class="inline-flex capitalize">
       <span>
        {{ $t(`${header.name}`) }}
       </span>
      </div>
     </th>
     <th scope="col" class="relative px-6 py-3">
      <span class="sr-only">Actions</span>
     </th>
    </tr>
   </thead>

   <tbody class="bg-white flex-1 sm:flex-none divide-y divide-gray-200">
    <tr
     v-for="participant in elementsData"
     :key="participant"
     class="hover:bg-gray-100 cursor-pointer">
     <td
      class="px-6 py-4 whitespace-nowrap"
      @click="
       $emit(
        'openEditForm',
        participant.name,
        participant.resources,
        participant.id
       )
      ">
      <div class="text-sm text-gray-900">
       {{ participant.name }}
      </div>
     </td>
     <td
      class="px-6 py-4"
      @click="
       $emit(
        'openEditForm',
        participant.name,
        participant.resources,
        participant.id
       )
      ">
      <div class="text-sm text-gray-900">
       <ul
        v-for="(value, idx) in JSON.parse(participant.resources)"
        :key="value"
        class="inline-flex">
        <li class="inline-flex mx-1">
         <span v-if="participant.type == 1"
          >{{ value
          }}<span v-show="idx < JSON.parse(participant.resources).length - 1"
           >,</span
          ></span
         >
         <span v-else
          >{{ elementLabel(value, participant.type)
          }}<span v-show="idx < JSON.parse(participant.resources).length - 1"
           >,</span
          ></span
         >
        </li>
       </ul>
       <!-- {{ String(Object.keys(elementsData[element])) }} -->
      </div>
     </td>
     <td class="p-3">
      <div class="flex justify-end">
       <a
        href="javascript:void(0)"
        @click="
         deleteItem(
          participant.id,
          $t('deleteItem'),
          $t('confirmationMessage'),
          $t('confirm'),
          $t('goBack'),
          $t('successMsg')
         )
        "
        class="p-1 shadow-sm border-2 border-black rounded-md body__table--button cursor-pointer tooltip mr-1"
        :title="$t('delete')">
        <svg
         xmlns="http://www.w3.org/2000/svg"
         class="sm:h-4 sm:w-4 h-4 w-4"
         viewBox="0 0 20 20"
         fill="currentColor">
         <path
          fill-rule="evenodd"
          d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
          clip-rule="evenodd" />
        </svg>
       </a>
       <a
        href="javascript:void(0)"
        @click="
         $emit(
          'openEditForm',
          participant.name,
          participant.resources,
          participant.id
         )
        "
        class="p-1 shadow-sm border-2 border-black rounded-md body__table--button cursor-pointer tooltip mr-1"
        :title="$t('edit')">
        <svg
         xmlns="http://www.w3.org/2000/svg"
         class="sm:h-4 sm:w-4 h-4 w-4"
         viewBox="0 0 20 20"
         fill="currentColor">
         <path
          d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
         <path
          fill-rule="evenodd"
          d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
          clip-rule="evenodd" />
        </svg>
       </a>
      </div>
     </td>
     <!-- <td class="px-6 py-4 " @click.prevent="goToCTI(cti.id)">
          <div class="text-sm text-gray-900 break-all" id="divClipboard">
            <p>
              {{ apiUrl }}/api/v1/cti/{{
                cti.token
              }}/%CallerDisplayName%/%CallerNumber%
            </p>
          </div>
        </td> -->
    </tr>
   </tbody>
  </table>
 </div>
 <ask-confirmation-dialog ref="askConfirmationDialog"></ask-confirmation-dialog>
</template>

<script>
import axios from "axios";
import AskConfirmationDialog from "../AskConfirmationDialog.vue";
import { mapGetters } from "vuex";
const account = localStorage.getItem("account");

export default {
 name: "GroupsList",
 props: ["searchName", "searchToken", "tablePaginatedData", "elementsData"],
 data() {
  return {
   tableHeaders: [{ name: "name" }, { name: "participants" }],
   account,
   closeForm: true,
   apiUrl: undefined,
  };
 },
 components: { AskConfirmationDialog },
 methods: {
  elementLabel(element, type) {
   let label = "";
   if (type == 4) {
    label =
     this.pbxMap &&
     this.pbxMap["call_queues"] &&
     this.pbxMap["call_queues"][element]
      ? this.pbxMap["call_queues"][element]["name"]
      : "";
   } else if (type == 0) {
    label =
     this.pbxMap &&
     this.pbxMap["extensions"] &&
     this.pbxMap["extensions"][element]
      ? this.pbxMap["extensions"][element]
      : "";
   }
   return element + ":" + label;
  },
  splitElementName(element) {
   let arrayNumbers = [];
   for (let index = 0; index < element.length; index++) {
    const el = element[index];
    let splitElement = el.split(":");
    arrayNumbers.push(splitElement[0]);
   }
   return arrayNumbers;
  },
  cancelForm() {
   this.closeForm = !this.closeForm;
   this.$forceUpdate();
  },
  async deleteItem(id, title, confirmationMessage, confirmButton, goBack, msg) {
   const r = await this.$refs.askConfirmationDialog.show({
    title: title,
    message: confirmationMessage,
    okButton: confirmButton,
    cancelButton: goBack,
    action: "delete",
   });
   if (r) {
    axios
     .delete(
      `${localStorage.getItem("apiUrl")}/api/v1.2/${
       this.hostName
      }/resource-groups/${id}`,
      {
       headers: {
        Authorization: `Bearer ${localStorage.getItem("apiToken")}`,
       },
      }
     )
     .then(res => {
      if (res) {
       this.$emit("activeBanner");
       this.$emit("success", true);
       this.$emit("successMsg", msg);
      }
     })
     .catch(err => {
      this.errorHandling(err);
      this.$emit("activeBanner");
      this.$emit("success", false);
      this.$emit("successMsg", this.errorDataResponse);
     });
   }
  },
 },
 mounted() {},
 computed: {
  ...mapGetters(["hostName", "pbxMap"]),
 },
};
</script>

<style>
.cti_table_heading {
 padding: 10px 40px 10px 40px;
}
.display_inline {
 display: inline;
}
</style>
