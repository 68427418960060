<template>
  <div class="bg-white shadow overflow-hidden sm:rounded-lg mb-3">
    <div class="flex justify-between px-4 py-5 sm:px-6">
      <div class="">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          {{ $t("reportDetailTitle") }}
        </h3>
        <p class="mt-1 max-w-2xl text-sm text-gray-500">
          {{ $t("reportDetailTitleDescription") }}.
        </p>
      </div>
      <div>
        <button
          type="button"
          class="capitalize inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white encom_pink_bg"
          @click="this.$emit('close', false)"
        >
          {{ $t("close") }}
          <XIcon class="ml-2 -mr-0.5 h-4 w-4" aria-hidden="true" />
        </button>
      </div>
    </div>
    <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
      <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
        <div class="sm:col-span-1">
          <dt class="capitalize text-sm font-medium text-gray-500">
            {{ $t("name") }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900">{{ details.nickname }}</dd>
        </div>
        <div class="sm:col-span-1">
          <dt class="capitalize text-sm font-medium text-gray-500">
            {{ $t("pbxHost") }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900">{{ details.pbx }}</dd>
        </div>
        <div class="sm:col-span-1">
          <dt class="text-sm font-medium text-gray-500">Type</dt>
          <dd class="capitalize mt-1 text-sm text-gray-900">
            {{ details.type }}
          </dd>
        </div>
        <div class="sm:col-span-1">
          <dt class="text-sm font-medium text-gray-500">
            {{ $t("emailAddress") }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ details.email_addresses }}
          </dd>
        </div>
        <div class="sm:col-span-2">
          <dt class="capitalize text-sm font-medium text-gray-500">
            {{ $t("preview") }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ $t("previewReport") }}
          </dd>
          <button
            type="button"
            class="capitalize mt-2 inline-flex items-center px-2 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white body__header--button encom_ring_color"
            @click="
              previewPresentation(details.sharing_key, parameters.dataSet)
            "
          >
            {{ $t("preview") }}

            <ExternalLinkIcon class="ml-2 -mr-0.5 h-5 w-5" aria-hidden="true" />
          </button>
        </div>
        <div class="sm:col-span-2">
          <dt class="text-sm font-medium text-gray-500">Parameters</dt>
          <dd class="mt-1 text-sm text-gray-900">
            <ul
              role="list"
              class="border border-gray-200 rounded-md divide-y divide-gray-200"
            >
              <li
                class="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
              >
                <div class="w-0 flex-1 flex items-center">
                  <ServerIcon
                    class="flex-shrink-0 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <span class="ml-2 flex-1 w-0 truncate">
                    {{ parameters.pbx }}
                  </span>
                </div>
              </li>
              <li
                class="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
              >
                <div class="w-0 flex-1 flex items-center">
                  <ChartBarIcon
                    class="flex-shrink-0 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <span class="capitalize ml-2 flex-1 w-0 truncate">
                    {{ $t(reportTypeTitle(parameters.dataSet)) }}
                  </span>
                </div>
              </li>
              <li
                class="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
              >
                <div class="w-0 flex-1 flex items-center">
                  <CalendarIcon
                    class="flex-shrink-0 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <span class="ml-2 flex-1 w-0 truncate">
                    {{ this.parseDateRange(parameters.period)
                    }}{{ this.parseTimeRange(parameters.time) }}
                  </span>
                </div>
              </li>
              <li
                class="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
              >
                <div class="w-0 flex-1 flex items-center">
                  <PhoneIcon
                    class="flex-shrink-0 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <span class="capitalize ml-2 flex-1 w-0 truncate">
                    {{ $t(returnParameterType(parameters.type)) }} :
                    {{ parameters.elements }}
                  </span>
                </div>
              </li>
            </ul>
          </dd>
        </div>
      </dl>
    </div>
  </div>
</template>

<script>
import {
  ExternalLinkIcon,
  ServerIcon,
  ChartBarIcon,
  CalendarIcon,
  PhoneIcon,
  XIcon,
} from "@heroicons/vue/solid";
export default {
  name: "ReportDetail",
  props: ["details", "parameters", "formatDateFrench"],
  data() {
    return {
      closeForm: true,
    };
  },
  components: {
    ExternalLinkIcon,
    ServerIcon,
    ChartBarIcon,
    CalendarIcon,
    PhoneIcon,
    XIcon,
  },
  methods: {
    previewPresentation(sharing_key, dataSet) {
      let url = `https://presentation.cx-engine.net/${dataSet}/${sharing_key}`;
      // let url = `https://fervent-euler-84aefa.netlify.app/${dataSet}/${sharing_key}`;
      window.open(url, "_blank");
    },
    cancelForm() {
      this.closeForm = !this.closeForm;
      this.$forceUpdate();
    },
    parseDateRange(date) {
      if (date) {
        if (date.length === 8) {
          let year = ("0" + date).slice(1, -4);
          let month = ("0" + date).slice(-4, -2);
          let day = ("0" + date).slice(-2);
          let fullDay = "";
          fullDay = day + "/" + month + "/" + year;
          return String(fullDay);
        } else if (date.length === 6) {
          let year = ("0" + date).slice(1, 5);
          let month = ("0" + date).slice(-2);
          let fullDay = "";
          fullDay = month + "/" + year;
          return String(fullDay);
        } else if (date.length === 17) {
          let fullDay = date.split("-");
          let firstYear = ("0" + fullDay[0]).slice(1, 5);
          let firstMonth = ("0" + fullDay[0]).slice(-4, -2);
          let firstDay = ("0" + fullDay[0]).slice(-2);

          let firstFullDay = "";
          firstFullDay = firstDay + "/" + firstMonth + "/" + firstYear;

          let lastYear = ("0" + fullDay[1]).slice(1, 5);
          let lastMonth = ("0" + fullDay[1]).slice(-4, -2);
          let lastDay = ("0" + fullDay[1]).slice(-2);

          let lastFullDay = "";
          lastFullDay = lastDay + "/" + lastMonth + "/" + lastYear;
          let finalDay = "";
          finalDay = firstFullDay + " - " + lastFullDay;
          return String(finalDay);
        } else {
          return date;
        }
      } else {
      }
    },
    parseTimeRange(time) {
      if (time) {
        const array = time.split(":");
        const timeStart = array[0].slice(0, 2) + ":" + array[0].slice(2);
        const timeEnd = array[1].slice(0, 2) + ":" + array[1].slice(2);
        const finalTime = timeStart + " - " + timeEnd;

        return ", " + finalTime;
      }
    },
    reportTypeTitle(dataSet) {
      return dataSet === "origins"
        ? "expertStatisticsNavigation.origins"
        : dataSet === "answered"
        ? "expertStatisticsNavigation.answeredUnansweredCalls"
        : dataSet === "originDetails"
        ? "expertStatisticsNavigation.originsTop10"
        : dataSet === "queueDetails"
        ? "queueDetail"
        : dataSet === "queuesComparison"
        ? "queueComparison"
        : "dashboards.custom";
    },
    returnParameterType(type) {
      return type === "queue" ? "queue" : "extension";
    },
  },
};
</script>

<style></style>
