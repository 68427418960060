<template>
  <table
    :class="[
      'min-w-full divide-y divide-gray-300',
    ]"
  >
    <thead class="bg-gray-50 divide-y divide-gray-200">
      <tr
        class="flex flex-col flex-wrap sm:table-row sm:rounded-none mb-2 sm:mb-0"
      >
        <th
          scope="col"
          :class="[
            idx === 0
              ? 'py-3.5 pl-4 pr-3 sm:pl-6'
              : 'px-3 py-3.5 lg:table-cell',
            'text-left text-sm font-semibold text-gray-900 capitalize',
          ]"
          v-for="(header, idx) in tableHeaders"
          :key="header.name"
        >
          <div class="inline-flex">
            <span>
              {{ $t(`${header.name}`) }}
            </span>
          </div>
        </th>
        <th scope="col" class="text-left px-6 py-3">
          <span class="sr-only">Actions</span>
        </th>
      </tr>
    </thead>
    <tbody class="flex-1 sm:flex-none divide-y divide-gray-200">
      <tr
        v-for="(cti, idx) in this.tablePaginatedData"
        :key="cti.id"
        :class="[
          idx % 2 === 0 ? 'bg-white' : `bg-gray-50`,
          'flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0 hover:bg-gray-100 cursor-pointer',
        ]"
      >
        <td
          class="px-6 py-4 whitespace-nowrap"
          @click.prevent="goToCTI(cti.id)"
        >
          <div class="text-sm text-gray-900">
            {{ cti.name }}
          </div>
        </td>
        <td class="px-6 py-4 " @click.prevent="goToCTI(cti.id)">
          <div class="text-sm text-gray-900 break-all" id="divClipboard">
            <p>
              {{ apiUrl }}/api/v1/cti/{{
                cti.token
              }}/%CallerDisplayName%/%CallerNumber%
            </p>
          </div>
        </td>
        <UpdateCTI
          :id="cti.id"
          :name="cti.name"
          @copyClipboard="copyClipboard"
          @openEditForm="$emit('openEditForm', cti.name, cti.id)"
          @activeBanner="$emit('activeBanner')"
          @successMsg="$emit('successMsg', $event)"
          @success="$emit('success', $event)"
        />
      </tr>
    </tbody>
  </table>
</template>

<script>
import UpdateCTI from "../components/UpdateCTI.vue";

const account = localStorage.getItem("account");

export default {
  name: "CTIsList",
  props: ["searchName", "searchToken", "tablePaginatedData"],
  data() {
    return {
      tableHeaders: [{ name: "name" }, { name: "token" }],
      account,
      closeForm: true,
      apiUrl: undefined,
    };
  },
  components: {
    UpdateCTI,
  },
  methods: {
    copyClipboard() {
      var elm = document.getElementById("divClipboard");
      // for Internet Explorer

      if (document.body.createTextRange) {
        var range = document.body.createTextRange();
        range.moveToElementText(elm);
        range.select();
        document.execCommand("Copy");
        this.$emit("activeBanner");
        this.$emit("success", true);
        this.$emit("successMsg", "Copied to clipboard.");
      } else if (window.getSelection) {
        // other browsers

        var selection = window.getSelection();
        var range = document.createRange();
        range.selectNodeContents(elm);
        selection.removeAllRanges();
        selection.addRange(range);
        document.execCommand("Copy");
        this.$emit("activeBanner");
        this.$emit("success", true);
        this.$emit("successMsg", "Copied to clipboard.");
      }
    },
    cancelForm() {
      this.closeForm = !this.closeForm;
      this.$forceUpdate();
    },
    goToCTI(id) {
      this.$router.push(`/cti-detail/${this.account}/${id}`);
    },
    getAPI() {
      this.apiUrl = this.$cookie.getCookie("API");
    },
  },
  mounted() {
    this.getAPI();
  },
};
</script>

<style>
.cti_table_heading {
  padding: 10px 40px 10px 40px;
}
</style>
